import client, { IDataResponse } from '../request'

export type User = {
  username: string
  id: string
  email: string
}

export type LoginState = {
  username: string
  password: string
}

/// swr
const findMyself = '/auth/me'

const login = (data: LoginState) => {
  return client.post('/auth/login', data, {
    baseURL:
      process.env.REACT_APP_AUTH_BASE_URL ?? process.env.REACT_APP_BASE_URL,
  })
}
/// axios

// processing
const toRow = (data: IDataResponse<User>): User[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element) => {
      return {
        ...element,
        key: element.id,
      }
    })
  }

  return []
}

// processing
const toPaginate = (data: IDataResponse<User>) => {
  return {
    total: data?.meta?.total ?? 0,
  }
}

const AuthService = {
  login,
  findMyself,
  toRow,
  toPaginate,
}

export default AuthService
