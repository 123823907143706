import React, { useState, ReactNode, useMemo } from 'react'
import {
  BookmarkIcon,
  Cog6ToothIcon,
  ShoppingBagIcon,
  StarIcon,
  UserIcon,
} from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import useAppClientRole from './useAppClientRole'
const useSidebarMenu = () => {
  const { t } = useTranslation('translation')
  const role = useAppClientRole()

  const sidebarMenu = useMemo<
    {
      name: string
      route: string
      icon: ReactNode
    }[]
  >(
    () => [
      {
        name: t('reviews'),
        route: '/dashboard/reviews',
        icon: <StarIcon width='20px' height='20px' />,
      },
      {
        name: t('requests'),
        route: '/dashboard/review-requests',
        icon: <BookmarkIcon width='20px' height='20px' />,
      },
      ...(['admin', 'owner'].includes(role ?? '')
        ? [
            {
              name: t('staff'),
              route: '/dashboard/collaborators',
              icon: <UserIcon width='20px' height='20px' />,
            },
          ]
        : []),
      {
        name: t('products'),
        route: '/dashboard/products',
        icon: <ShoppingBagIcon width='20px' height='20px' />,
      },
      ...(['admin', 'owner'].includes(role ?? '')
        ? [
            {
              name: t('settings'),
              route: '/dashboard/settings',
              icon: <Cog6ToothIcon width='20px' height='20px' />,
            },
          ]
        : []),
    ],
    [t, role]
  )

  return sidebarMenu
}

export default useSidebarMenu
