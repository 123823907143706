const EnTranslation: Record<string, string> = {
  reviews: 'Reviews',
  requests: 'Requests',
  staff: 'Staff',
  products: 'Products',
  settings: 'Settings',
  english: 'English',
  japanese: 'Japanese',
  chineseTw: 'Chinsese (Traditional)',
  chineseCn: 'Chinsese (Simplified)',
  korean: 'Korean',

  //reviews
  title: 'Title',
  content: 'Content',
  name: 'Name',
  email: 'Email',
  product: 'Product',
  ratings: 'Ratings',
  verified: 'Verified',
  createdAt: 'Created At',
  publication: 'Publication',
  publish: 'Publish',
  unpublish: 'Unpublish',
  notVerified: 'Not Verified',

  //product
  productName: 'Product Name',
  shopifyId: 'Shopify ID',

  //search
  removeFilters: 'Remove Filters',
  search: 'Search',
  searchScope: 'Search Scope',
  chooseScope: 'Plase choose a search scope',

  //review request
  delete: 'Delete',
  deleteNumberRequest: 'Delete {{number}} request(s)?',
  deletedNotReversible: 'This action is not reversible',
  orderNumber: 'OrdeR Number',
  status: 'Status',
  sentAt: 'Sent At',
  reviewedAt: 'Reviewed At',

  //dashboard
  dashboard: 'Dashboard',
  reviewRatingTopNumber: 'Top {{value}} Highest Average Ratings',
  averageRatings: 'Average Ratings',
  totalReviews: 'Total Reviews',

  //general
  cancel: 'Cancel',
  confirmDelete: 'Confirm Delete',
  deletedCount: '{{number}} row(s) of data has been deleted',
  deleteFail: 'Deletion Fail',
  moreActions: 'More Actions',
}

export default EnTranslation
