import _ from 'lodash'
import client, { IDataResponse } from '../request'
import { Product } from './product'
import { RcFile } from 'antd/es/upload'

export interface ReviewTranslation {
  title?: string
  content: string
}

export interface Review {
  id: number
  title: string
  content: string
  name: string
  email: string
  score: number
  published: boolean
  product_id: string
  imported_at?: string
  created_at: string
  updated_at: string
  verifiedEmail?: {
    id: number
    email: string
  }
  translation: {
    en?: ReviewTranslation
    ja?: ReviewTranslation
    ko?: ReviewTranslation
    zh?: ReviewTranslation
    'zh-TW'?: ReviewTranslation
    original: 'en' | 'ko' | 'zh' | 'zh-TW' | 'ja'
  }
  product: Product
  variants: any[]
  uploads: ReviewUpload[]
  up_votes: number
  down_votes: number
  up_votes_offset: number
  down_votes_offset: number
  external_import_id?: string
}

export interface ReviewUpload {
  id: number
  review_id: number
  file_name: string
  url: string
  created_at: string
  updated_at: string
}

export interface ReviewUpdateState {
  published?: boolean
  up_votes_offset?: number
  down_votes_offset?: number
}

export interface CreateEmailReviewState {
  title?: string
  content: string
  score: number
  name?: string
  token: string
  email: string
  attachments?: RcFile[]
}

const getAll = (appClientId: string) => {
  return `/admin/app-clients/${appClientId}/reviews`
}

const get = (appClientId: string, id: string | number) => {
  return `/admin/app-clients/${appClientId}/reviews/${id}`
}

const publicEmailCreate = (data: CreateEmailReviewState) => {
  const formData = new FormData()
  formData.append('token', data.token)
  formData.append('title', data.title ?? '')
  formData.append('score', _.toString(data.score))
  formData.append('name', data.name ?? '')
  formData.append('email', data.email)
  formData.append('content', data.content)
  if (data.attachments) {
    for (var i = 0; i < data.attachments?.length; i++) {
      formData.append(`attachments[${i}]`, data.attachments[i])
    }
  }
  return client.post('/public/email-reviews', formData)
}

const update = (
  appClientId: string,
  id: string | number,
  data: ReviewUpdateState
) => {
  return client.put(`/admin/app-clients/${appClientId}/reviews/${id}`, data)
}

const toRow = (data: IDataResponse<Review>) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id,
      }
    })
  }

  return []
}

const toPaginate = (data: IDataResponse<Review>) => {
  return {
    total: data?.meta?.total ?? 0,
  }
}

const remove = (appClientId: string, id: string | number) => {
  return client.delete(`/admin/app-clients/${appClientId}/reviews/${id}`)
}

const ReviewService = {
  getAll,
  get,
  update,
  toRow,
  toPaginate,
  remove,
  publicEmailCreate,
}

export default ReviewService
