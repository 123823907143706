import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Card,
  Col,
  Row,
  message,
  Button,
  Typography,
  Input,
  Table,
  Modal,
  InputRef,
  Form,
} from 'antd'

import Gaps from '../../components/Gaps'
import _ from 'lodash'
import appClientStore from '../../lib/store/appClient'
import { useSnapshot } from 'valtio'
import AppSettingsService, {
  AppSettng,
  DomainStatus,
} from '../../network/services/settings'
import useSWR from 'swr'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { green, red } from '@ant-design/colors'
import { findAllByDisplayValue } from '@testing-library/react'
import { useForm } from 'antd/es/form/Form'
// import { useMediaQuery } from 'react-responsive'

const DomainSettingForm: FC<{
  initialValue: AppSettng
  refresh: () => any
}> = ({ refresh, initialValue }) => {
  const {
    state: { id: appClientId },
  } = useSnapshot(appClientStore)

  const [form] = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [modalDisplay, setModalDisplay] = useState(false)
  const domain = Form.useWatch('domain', form)
  const { data: domainStatus, mutate } = useSWR<DomainStatus | null>(
    initialValue.domain
      ? AppSettingsService.getDomainStatus(appClientId!)
      : null
  )

  useEffect(() => {
    form.setFieldValue('domain', initialValue.domain)
  }, [initialValue])

  const handleConfigureDomain = async () => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const { data: result } = await AppSettingsService.configureDomain(
        appClientId!,
        { domain }
      )
      if (result.success) {
        message.success('Configure successful')
        await mutate()
        refresh()
      }
    } catch (error: any) {
      message.error('Configure domain error ' + error.message)
    }

    setIsLoading(false)
  }

  const handleVerifyDomain = async () => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const { data: result } = await AppSettingsService.verifyDomain(
        appClientId!
      )
      if (result.success) {
        message.success('Verify successful')
        await mutate()
        refresh()
      }
    } catch (error: any) {
      message.error('Verify domain error ' + error.message)
    }

    setIsLoading(false)
  }

  const handleDeleteDomain = async () => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const { data: result } = await AppSettingsService.deleteDomain(
        appClientId!
      )
      if (result.success) {
        message.success('Delete successful')
        await mutate()
        refresh()
      }
    } catch (error: any) {
      message.error('Delete domain error ' + error.message)
    }

    setIsLoading(false)
  }

  const handleConfirmDeleteDomain = () => {
    setModalDisplay(true)
  }

  return (
    <>
      <Modal
        title='Warning'
        open={modalDisplay}
        onOk={handleDeleteDomain}
        onCancel={() => setModalDisplay(false)}
      >
        Are you sure you want to remove your domain?
      </Modal>

      <Form form={form}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card bordered={false}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography.Title level={3} style={{ margin: 0 }}>
                  Domain & Email
                </Typography.Title>
                <Button
                  type='default'
                  loading={isLoading}
                  onClick={handleConfirmDeleteDomain}
                >
                  Delete
                </Button>
              </div>
              <Gaps level={2} />
              <div>
                <div>Domain name</div>
                <div
                  style={{
                    marginTop: '8px',
                    display: 'flex',
                  }}
                >
                  <Form.Item
                    initialValue={initialValue.domain ?? ''}
                    name='domain'
                  >
                    <Input style={{ width: 250, marginRight: 8 }} />
                  </Form.Item>
                  <Button
                    type='primary'
                    loading={isLoading}
                    onClick={handleConfigureDomain}
                  >
                    Configure
                  </Button>
                </div>
              </div>
              <Gaps level={3} />
              {initialValue.domain && domainStatus && (
                <>
                  <Table
                    dataSource={[
                      { ...domainStatus.dns.dkim1 },
                      { ...domainStatus.dns.dkim2 },
                      { ...domainStatus.dns.mail_cname },
                    ]}
                    pagination={false}
                    columns={[
                      {
                        title: 'Valid',
                        render: (record: any) =>
                          record.valid ? (
                            <CheckCircleIcon width={36} color={green[1]} />
                          ) : (
                            <XCircleIcon width={36} color={red[1]} />
                          ),
                        key: 'valid',
                      },
                      {
                        title: 'Type',
                        render: (record: any) => _.toUpper(record.type),
                        key: 'type',
                      },
                      {
                        title: 'Host',
                        render: (record: any) => (
                          <Typography.Text copyable>
                            {record.host}
                          </Typography.Text>
                        ),
                        key: 'host',
                      },
                      {
                        title: 'Value',
                        render: (record: any) => (
                          <Typography.Text copyable>
                            {record.data}
                          </Typography.Text>
                        ),
                        key: 'data',
                      },
                    ]}
                  />
                  <Gaps level={2} />
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      disabled={
                        domainStatus.valid && initialValue.domain_verified
                      }
                      type='primary'
                      loading={isLoading}
                      onClick={handleVerifyDomain}
                    >
                      {domainStatus.valid && initialValue.domain_verified
                        ? 'Verified'
                        : 'Verify'}
                    </Button>
                  </div>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default DomainSettingForm
