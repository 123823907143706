import React, { useState, useEffect, FC } from 'react'
import { Space, Form, Card, Typography, Input, Button, Row, Col } from 'antd'
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
import { useSnapshot } from 'valtio'
import { Navigate, useLocation, Link, useSearchParams } from 'react-router-dom'

import { getToken, login } from '../lib/store/auth'
import AuthService, { LoginState } from '../network/services/auth'
import appClientStore from '../lib/store/appClient'

const Login: FC = () => {
  let location = useLocation()
  const [searchParams] = useSearchParams()
  const [form] = Form.useForm<LoginState>()
  const [redirectToReferrer, setRedirectToReferrer] = useState(false)
  const [formError, setFormError] = useState(null)

  useEffect(() => {
    const loggedin = getToken()
    if (loggedin) setRedirectToReferrer(true)
  })

  const handleLogin = async (data: LoginState) => {
    try {
      const { data: result } = await AuthService.login(data)

      const profile = result.user
      const token = result.access_token
      appClientStore.reset()
      login({ profile, token: token })

      setRedirectToReferrer(true)
      //   globalStore.clearMenu()
      //   tableStore1.reset()
      //   tableStore2.reset()
    } catch (e: any) {
      setFormError(e.message)
    }
  }

  const { from } = location.state || { from: { pathname: '/dashboard' } }

  console.log(searchParams.get('redirect_uri'))
  if (redirectToReferrer) {
    const redirectUri = searchParams.get('redirect_uri')
    if (redirectUri && redirectUri != undefined) {
      window.location.href = redirectUri
    }
    return <Navigate to={from} />
  }

  return (
    <Row
      justify='center'
      align='middle'
      style={{ height: '100vh', width: '100%' }}
    >
      <Col>
        <Space direction='vertical'>
          <Link to='/dashboard'>
            <div
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <Typography.Title>Solutions Reviews</Typography.Title>
            </div>
          </Link>

          <Card style={{ minWidth: '400px' }}>
            <Form onFinish={handleLogin} autoComplete='off' form={form}>
              <Form.Item
                name='username'
                rules={[
                  {
                    required: true,
                    message: 'Please insert your username',
                  },
                ]}
              >
                <Input size='large' placeholder='Email' autoComplete='off' />
              </Form.Item>

              <Form.Item
                name='password'
                rules={[
                  {
                    required: true,
                    message: 'Please insert your password',
                  },
                ]}
                style={{ marginBottom: 4 }}
              >
                <Input.Password
                  size='large'
                  type='password'
                  placeholder='Password'
                  autoComplete='off'
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>

              <p style={{ color: 'red', textTransform: 'capitalize' }}>
                {formError}
              </p>

              <Form.Item>
                <Button type='primary' htmlType='submit'>
                  Sign In
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Space>
      </Col>
    </Row>
  )
}

export default Login
