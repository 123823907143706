import { User } from './auth'
import client from '../request'

export enum Roles {
  ADMIN = 'admin',
  STAFF = 'staff',
}

export interface Collaborator {
  id: number
  role: Roles
  user: User
}

export interface ShopifyOauthState {
  code: string
  hmac: string
  shop: string
  state: string
  timestamp: number | string
}

const auth = (data: ShopifyOauthState) => {
  return client.post('/admin/shopify/auth', data)
}

const ShopifyOauthService = {
  auth,
}

export default ShopifyOauthService
