import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useState,
} from 'react'
import {
  Card,
  Col,
  Form,
  Row,
  message,
  Button,
  Image,
  Divider,
  Typography,
  Upload,
  FormInstance,
  Rate,
  Input,
  Select,
} from 'antd'

import ReviewService, {
  Review,
  ReviewUpdateState,
} from '../../network/services/reviews'

import Gaps from '../../components/Gaps'
import _ from 'lodash'
import { PlusOutlined } from '@ant-design/icons'
import ReadOnlyFormItem from '../../components/ReadOnlyFormItem'
import Link from 'antd/es/typography/Link'
import appClientStore from '../../lib/store/appClient'
import { useSnapshot } from 'valtio'
import { green, red } from '@ant-design/colors'
import CollaboratorService, {
  Collaborator,
  CollaboratorCreateState,
} from '../../network/services/collaborators'
import { useNavigate, useParams } from 'react-router-dom'
// import { useMediaQuery } from 'react-responsive'

const CollaboratorForm: FC<{
  form: FormInstance<CollaboratorCreateState>
  initialValue?: Collaborator
  refresh: () => any
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
}> = ({ form, initialValue, refresh, isLoading, setIsLoading }) => {
  const { id } = useParams()
  const {
    state: { id: appClientId },
  } = useSnapshot(appClientStore)
  const navigate = useNavigate()

  const handleSubmit = async (values: CollaboratorCreateState) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      try {
        const { data: result } = await CollaboratorService.update(
          appClientId!,
          initialValue.id,
          values
        )
        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error: any) {
        message.error('Update collaborator error ' + error.message)
      }
    } else {
      try {
        const { data: result } = await CollaboratorService.create(
          appClientId!,
          values
        )
        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/collaborators/${result.data.id}`)
        }
      } catch (error: any) {
        message.error('Create collaborator error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  return (
    <>
      <Form
        form={form}
        initialValues={
          initialValue
            ? {
                ...initialValue,
                username: initialValue.user.username,
              }
            : {}
        }
        onFinish={handleSubmit}
        layout='vertical'
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card bordered={false}>
              <Form.Item
                name='user_id'
                label={id === 'new' ? 'Username or User ID' : 'Username'}
                rules={[
                  {
                    required: true,
                    message: 'Username is required',
                  },
                ]}
              >
                <Input readOnly={id !== 'new'} />
              </Form.Item>

              <Form.Item
                name='role'
                label='Role'
                rules={[
                  {
                    required: true,
                    message: 'Username is required',
                  },
                ]}
              >
                <Select>
                  <Select.Option id='admin' value='admin'>
                    Admin
                  </Select.Option>
                  <Select.Option id='staff' value='staff'>
                    Staff
                  </Select.Option>
                </Select>
              </Form.Item>

              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              ></div>
            </Card>
          </Col>
        </Row>
        <Gaps />
      </Form>
    </>
  )
}

export default CollaboratorForm
