import { ThemeConfig, theme as antTheme } from 'antd'

const theme: ThemeConfig = {
  algorithm: antTheme.defaultAlgorithm,
  token: {
    fontFamily: 'Noto Sans JP',
  },
}

export default theme
