import client, { IDataResponse } from '../request'
import { Product } from './product'

export interface AppClient {
  id: string
  name: string
  shopify_store_id: string
  shopify_store_name: string
  role: 'staff' | 'owner' | 'admin'
  created_at: string
  updated_at: string
}

const getAll = '/admin/app-clients'

//swr
const get = (id: string) => {
  return `/admin/app-clients/${id}`
}

const toRow = (data: IDataResponse<AppClient>) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id,
      }
    })
  }

  return []
}

const toPaginate = (data: IDataResponse<AppClient>) => {
  return {
    total: data?.meta?.total ?? 0,
  }
}

const AppClientService = {
  getAll,
  get,
  toRow,
  toPaginate,
}

export default AppClientService
