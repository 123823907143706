import { Product } from './product'

export type AverageRatingStat = {
  total_reviews: number
  avg_rating: string
  product: Product
}

const getAll = (appClientId: string) => {
  return `/admin/app-clients/${appClientId}/statistics/ratings-avg`
}

const get = (appClientId: string, id: string | number) => {
  return `/admin/app-clients/${appClientId}/statistics/ratings-avg/${id}`
}

const StatisticsService = {
  getAll,
  get,
}

export default StatisticsService
