import React, { FC, useEffect } from 'react'
import { Layout, Row, Space, Typography, Menu, Select, Button } from 'antd'
import { useSnapshot } from 'valtio'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  TranslationOutlined,
  LogoutOutlined,
} from '@ant-design/icons'
import { Link, Outlet } from 'react-router-dom'

import globalStore from '../lib/store/global'
import { getToken, logout } from '../lib/store/auth'
import useSidebarMenu from '../hooks/useSidebarMenu'
import useSWR from 'swr'
import { IDataResponse } from '../network/request'
import AppClientService, { AppClient } from '../network/services/appClient'
import appClientStore from '../lib/store/appClient'
import languageStore from '../lib/store/language'
import reviewRequestStore from '../lib/store/review_request_table'
import reviewStore from '../lib/store/review_table'
import productStore from '../lib/store/product_table'
import collaboratorStore from '../lib/store/collaborator_table'
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/outline'

const { Header, Footer, Sider, Content } = Layout

const AuthLayout: FC = () => {
  const { data: appClientsData } = useSWR<IDataResponse<AppClient>>(
    AppClientService.getAll
  )
  const { state: appClientState } = useSnapshot(appClientStore)
  const { state: languagestate } = useSnapshot(languageStore)
  const { collapsed } = useSnapshot(globalStore)
  const options = useSidebarMenu()

  const toggle = () => {
    globalStore.collapsed = !collapsed
  }

  const setAppClient = (id: string) => {
    const target = appClientsData?.data.find((item) => item.id == id)
    //TODO: display error
    if (!target) return
    appClientStore.state.id = target.id
    appClientStore.state.name = target.name

    reviewRequestStore.reset()
    reviewStore.reset()
    productStore.reset()
    collaboratorStore.reset()
  }

  useEffect(() => {
    if (!appClientState.id && appClientsData?.data.length) {
      appClientStore.state.id = appClientsData.data[0].id
      appClientStore.state.name = appClientsData.data[0].name
    }
  }, [appClientState, appClientsData])

  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        style={{ backgroundColor: '#ffffff' }}
        width={250}
        collapsed={collapsed}
      >
        {/* collapsed={collapsed}> */}
        <Space
          className='sider__space'
          direction='vertical'
          style={{
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <div style={{ paddingLeft: 16, paddingRight: 16 }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                minHeight: 60,
              }}
            >
              <Link to='/dashboard'>
                {collapsed ? (
                  <Typography.Title level={4} style={{ margin: 0 }}>
                    <img
                      src='/logo512.png'
                      alt=''
                      style={{
                        objectFit: 'contain',
                        marginLeft: '14px',
                      }}
                      width={20}
                    />
                  </Typography.Title>
                ) : (
                  <Typography.Title level={4} style={{ margin: 0 }}>
                    <img
                      src='/senders logo_black.png'
                      alt=''
                      style={{
                        objectFit: 'contain',
                      }}
                      width={100}
                    />
                  </Typography.Title>
                )}
              </Link>
              {collapsed ? (
                <ChevronDoubleRightIcon
                  onClick={toggle}
                  color='black'
                  width={26}
                />
              ) : (
                <ChevronDoubleLeftIcon
                  onClick={toggle}
                  color='black'
                  width={26}
                />
              )}
            </div>
            {!collapsed && (
              <Select
                value={appClientState.id}
                onChange={setAppClient}
                style={{ width: '100%', textAlign: 'center' }}
                options={appClientsData?.data.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            )}
          </div>

          <Menu style={{ border: 'none' }}>
            {options.map((option) => (
              <Menu.Item
                key={option.name}
                style={{ paddingLeft: 16, paddingRight: 16, marginLeft: 0 }}
              >
                <Link to={`${option.route}`}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {option.icon}
                    {collapsed ? null : <span>{option.name}</span>}
                  </div>
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </Space>
      </Sider>
      <Layout style={{ minHeight: '100vh' }}>
        <Header
          style={{
            justifyContent: 'flex-end',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Space>
            <TranslationOutlined />
            <Select
              value={languagestate.language}
              onChange={(value) => {
                languageStore.state.language = value
              }}
              style={{ width: 200, textAlign: 'center' }}
              options={[
                {
                  label: 'English',
                  value: 'en',
                },
                {
                  label: '日本語',
                  value: 'ja',
                },
              ]}
            />

            <Button type='text' onClick={logout}>
              <LogoutOutlined
                width={150}
                height={80}
                style={{ color: 'white' }}
              />
            </Button>
          </Space>
        </Header>
        <Content>
          <Outlet />
        </Content>
        <Footer>
          <Row>
            <Typography.Text style={{ margin: 'auto' }}>
              copyright @ {new Date().getFullYear()}
            </Typography.Text>
          </Row>
        </Footer>
      </Layout>
    </Layout>
  )
}

export default AuthLayout
