import { Button, Card, Empty, Spin } from 'antd'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'

import tableStore from '../../lib/store/collaborator_table'
import { serialize } from '../../network/request'
import { PageHeader } from '@ant-design/pro-layout'
import appClientStore from '../../lib/store/appClient'
import ReviewRequestService from '../../network/services/reviewRequests'
import CollaboratorTable from './CollaboratorTable'
import { useNavigate, useParams } from 'react-router-dom'
import CollaboratorService from '../../network/services/collaborators'
const CollaboratorPage = () => {
  const navigate = useNavigate()
  const primaryAction = (
    <Button
      key='add'
      type='primary'
      onClick={() => {
        navigate('new')
      }}
    >
      Add collaborator
    </Button>
  )

  return (
    <PageHeader title='All Collaborators' extra={[primaryAction]}>
      <Card>
        <CollaboratorTableWrapper />
      </Card>
    </PageHeader>
  )
}

const CollaboratorTableWrapper = () => {
  const {
    state: { id: appClientId },
  } = useSnapshot(appClientStore)
  const { state } = useSnapshot(tableStore)

  // use this to preload
  const { data: response, error } = useSWR(
    serialize(CollaboratorService.getAll(appClientId!), {
      page: state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters,
    })
  )

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return <Spin />
  }

  const { total } = ReviewRequestService.toPaginate(response)

  return (
    <>
      <CollaboratorTable total={total} />
      {/* preload next page */}
      {state.currentPage * state.pageSize < total && (
        <div style={{ display: 'none' }}>
          <CollaboratorTable
            total={total}
            overridePage={state.currentPage + 1}
          />
        </div>
      )}
    </>
  )
}

export default CollaboratorPage
