import { FC, useEffect, useState } from 'react'
import { Table, Button, Empty, Modal, Row, Col, Dropdown, Tag } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../lib/store/review_request_table'
import TableFilter, { ITableFilter } from '../../components/TableFilter'
import { IDataResponse, serialize } from '../../network/request'
import Gaps from '../../components/Gaps'
import appClientStore from '../../lib/store/appClient'
import CollaboratorService, {
  Collaborator,
} from '../../network/services/collaborators'

const CollaboratorTable: FC<{ total: number; overridePage?: number }> = ({
  total,
  overridePage,
}) => {
  const {
    state: { id: appClientId },
  } = useSnapshot(appClientStore)
  const navigate = useNavigate()
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState<any>(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([])
  const [visible, setVisible] = useState(false)

  const { data: response } = useSWR<IDataResponse<Collaborator>>(
    serialize(CollaboratorService.getAll(appClientId!), {
      page: overridePage ?? state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters,
    })
  )
  const rows = response ? CollaboratorService.toRow(response) : []

  const rowSelection = {
    selectedRowKeys,
    onChange: (keySelected: any[]) => {
      setSelectedRowKeys(keySelected)
    },
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: 'Username',
      key: 'username',
      render: (record: Collaborator) => record.user.username,
      sorter: true,
    },
    {
      title: 'User ID',
      key: 'user_id',
      render: (record: Collaborator) => record.user.id,
      sorter: true,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      sorter: true,
    },
  ]

  // content={
  //   initialValue.variants.length > 0
  //     ? initialValue.variants.map((item) => item.name).join(',')
  //     : '-'
  // }

  const filterColumns: ITableFilter[] = [
    { key: 'username', name: 'Username' },
    { key: 'user_id', name: 'User ID' },
    { key: 'role', name: 'Role' },
  ]
  filterColumns.forEach((item) => {
    if (
      tableStore.state?.filters &&
      Object.keys(tableStore.state.filters).includes(item.key)
    ) {
      item.default_value = tableStore.state.filters[item.key]
      item.default = true
    }
  })

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const handleMenuClick = (e: any) => {
    setVisible(true)
    // setModalType(e.key)
  }

  const batchDeleteRows = async () => {
    // try {
    //   for (const id of selectedRowKeys) {
    //     const { data } = await ReviewService.remove(id)
    //     if (data?.success === true) {
    //       message.success(`Delete source success`)
    //     } else {
    //       message.error(`Delete source ${id} failed`)
    //     }
    //     tableStore.state.refresh = Math.random()
    //   }
    //   setVisible(false)
    // } catch (e) {
    //   message.error({ content: e.message, className: 'message-error' })
    // }
  }

  // const ActionModal: FC = () => {
  //   return (
  //     <Modal
  //       title={`Remove ${selectedRowKeys.length} reviews`}
  //       open={visible}
  //       onOk={() => {
  //         switch(modalType){
  //           case 'delete':
  //             batchDeleteRows()
  //             break;
  //           case 'publish':

  //         }
  //       }}
  //       onCancel={() => {
  //         setVisible(false)
  //       }}
  //       okText='Delete'
  //       cancelText='Cancel'
  //     >
  //       This can't be undone
  //     </Modal>
  //   )
  // }

  return (
    <>
      {/* <ActionModal /> */}

      <Row>
        <Col span={12}>
          {selectedRowKeys?.length > 0 ? (
            <Dropdown
              menu={{
                items: [
                  {
                    label: 'Delete',
                    onClick: handleMenuClick,
                    key: 'delete',
                  },
                ],
              }}
              trigger={['click']}
            >
              <Button type='primary'>
                More Actions <CaretDownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <></>
          )}
        </Col>
        <Col span={12}>
          <TableFilter
            dropdowns={[]}
            columns={filterColumns}
            hasDate={false}
            setCurrentFilters={setCurrentFilters}
          />
          <Gaps level={3} />
        </Col>
      </Row>

      {rows?.length > 0 ? (
        <Table
          columns={columns}
          dataSource={rows}
          onChange={(pagination, filters, sorter: any) => {
            tableStore.state.sort =
              sorter != null &&
              !_.isEmpty(sorter?.columnKey) &&
              !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null

            tableStore.state.currentPage = pagination.current ?? 1
            tableStore.state.pageSize = pagination.pageSize ?? 10
          }}
          pagination={{
            showSizeChanger: true,
            total,
            pageSize: state.pageSize,
            current: state.currentPage,
            pageSizeOptions: [10, 20, 50, 100, 200, 300],
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (selectedRowKeys?.length > 0) {
                  if (selectedRowKeys.includes(record.id)) {
                    const filterKeys = _.filter(
                      [...selectedRowKeys],
                      function (o) {
                        return o !== record.id
                      }
                    )
                    setSelectedRowKeys([...filterKeys])
                  } else {
                    setSelectedRowKeys([...selectedRowKeys, record.id])
                  }
                } else {
                  navigate(`${record.id}`)
                }
              }, // click row
            }
          }}
          rowSelection={rowSelection}
        />
      ) : (
        <Empty description={'No record found'} />
      )}
    </>
  )
}

export default CollaboratorTable
