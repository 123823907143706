import client from '../request'

export interface DomainStatus {
  valid: boolean
  dns: {
    mail_cname: {
      valid: boolean
      type: string
      host: string
      data: string
    }
    dkim1: {
      valid: boolean
      type: string
      host: string
      data: string
    }
    dkim2: {
      valid: boolean
      type: string
      host: string
      data: string
    }
  }
}

export interface AppSettng {
  send_email_after_days: number
  email_after_purchase: boolean
  read_only: boolean
  domain?: string
  from?: string
  slack_webhook_url?: string
  from_name?: string
  domain_verified: boolean
  email_template_id?: string
  email_subject: string | null
  review_form_domain: string | null
  created_at: string
  updated_at: string
}

export interface AppSettingUpdateState {
  send_email_after_days: number
  email_after_purchase: boolean
  read_only: boolean
  slack_webhook_url?: string | null
  email_template_id?: string
  from?: string | null
  from_name?: string | null
  email_subject?: string | null
  review_form_domain?: string | null
}

export interface DomainUpdateState {
  domain: string
}

const get = (appClientId: string) => {
  return `/admin/app-clients/${appClientId}/settings`
}
const getDomainStatus = (appClientId: string) => {
  return `/admin/app-clients/${appClientId}/settings/domain`
}

const configureDomain = (appClientId: string, data: DomainUpdateState) => {
  return client.post(`/admin/app-clients/${appClientId}/settings/domain`, data)
}

const deleteDomain = (appClientId: string) => {
  return client.delete(`/admin/app-clients/${appClientId}/settings/domain`)
}

const verifyDomain = (appClientId: string) => {
  return client.post(`/admin/app-clients/${appClientId}/settings/verify-domain`)
}

const update = (appClientId: string, data: AppSettingUpdateState) => {
  return client.put(`/admin/app-clients/${appClientId}/settings`, data)
}

const AppSettingsService = {
  get,
  update,
  getDomainStatus,
  configureDomain,
  deleteDomain,
  verifyDomain,
}

export default AppSettingsService
