import { useNavigate, useParams } from 'react-router-dom'
import { Form, Empty, Spin, Button } from 'antd'
import useSWR from 'swr'
import appClientStore from '../../lib/store/appClient'
import { PageHeader } from '@ant-design/pro-layout'
import { useSnapshot } from 'valtio'
import CollaboratorService, {
  CollaboratorCreateState,
} from '../../network/services/collaborators'
import { useState } from 'react'
import CollaboratorForm from './CollaboratorForm'

const CollaboratorDetail = () => {
  const navigate = useNavigate()
  const {
    state: { id: appClientId },
  } = useSnapshot(appClientStore)
  const { id } = useParams()

  const [form] = Form.useForm<CollaboratorCreateState>()
  const [isLoading, setIsLoading] = useState(false)

  // use this to preload
  const {
    data: response,
    error,
    mutate,
  } = useSWR(id !== 'new' ? CollaboratorService.get(appClientId!, id!) : null)

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response && id !== 'new') {
    return (
      <PageHeader>
        <Spin />
      </PageHeader>
    )
  }

  const refresh = () => {
    if (id != null) {
      if (id === 'new') {
      } else {
        mutate()
      }
    }
  }

  const saveAction = (
    <Button
      key={'save'}
      type='primary'
      onClick={() => {
        form.submit()
      }}
      loading={isLoading}
    >
      Save
    </Button>
  )

  const discardAction = (
    <Button
      key={'discard'}
      type='ghost'
      onClick={async () => {
        await CollaboratorService.remove(appClientId!, id!)
        navigate('/dashboard/collaborators')
      }}
    >
      Discard
    </Button>
  )

  return (
    <PageHeader
      title={id === 'new' ? 'New Collaborator' : 'Manage Collaborator'}
      onBack={() => navigate('/dashboard/collaborators')}
      extra={[saveAction, discardAction]}
    >
      <CollaboratorForm
        form={form}
        initialValue={response}
        refresh={refresh}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </PageHeader>
  )
}

export default CollaboratorDetail
