import { useNavigate, useParams } from 'react-router-dom'
import { Form, Empty, Spin, Button, Modal, Select, message } from 'antd'
import useSWR from 'swr'
import appClientStore from '../../lib/store/appClient'
import { PageHeader } from '@ant-design/pro-layout'
import ProductForm from './ProductForm'
import { useSnapshot } from 'valtio'
import ProductService, {
  Product,
  ProductLinkCreateState,
} from '../../network/services/product'
import { useForm } from 'antd/es/form/Form'
import { Dispatch, SetStateAction, useState } from 'react'
import AppClientService from '../../network/services/appClient'
import { serialize } from '../../network/request'

const ProductDetail = () => {
  const navigate = useNavigate()
  const {
    state: { id: appClientId },
  } = useSnapshot(appClientStore)
  const { id } = useParams()
  const [openLinkProductModal, setOpenLinkProductModal] = useState(false)

  // use this to preload
  const {
    data: response,
    error,
    mutate,
  } = useSWR<Product>(ProductService.get(appClientId!, id!))

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return (
      <PageHeader>
        <Spin />
      </PageHeader>
    )
  }

  const refresh = () => {
    if (id != null) {
      if (id === 'new') {
      } else {
        mutate()
      }
    }
  }

  const linkReview = (
    <Button
      type='primary'
      onClick={() => {
        setOpenLinkProductModal(true)
      }}
    >
      Link review from another store
    </Button>
  )

  return (
    <PageHeader
      extra={[linkReview]}
      title='Manage Review'
      onBack={() => navigate('/dashboard/reviews')}
    >
      <LinkReviewModal
        open={openLinkProductModal}
        setOpen={setOpenLinkProductModal}
        refresh={refresh}
      />

      <ProductForm initialValue={response} refresh={refresh} />
    </PageHeader>
  )
}

const LinkReviewModal = ({
  open,
  setOpen,
  refresh,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  refresh: () => void
}) => {
  const {
    state: { id: appClientId },
  } = useSnapshot(appClientStore)
  const { id } = useParams()
  const [form] = useForm<ProductLinkCreateState>()
  const { data: productLinkesData, mutate: mutateLinks } = useSWR(
    serialize(ProductService.getLinkedProducts(appClientId!, id!), {
      limit: 100,
    })
  )
  const productLinks = productLinkesData
    ? ProductService.productLinkToRow(productLinkesData)
    : []

  const selectedAppClientId = Form.useWatch('app_client_id', form)
  const [loading, setLoading] = useState(false)

  const { data: appClientsData } = useSWR(AppClientService.getAll)
  const appClients = appClientsData
    ? AppClientService.toRow(appClientsData) //.filter((e) => e.id != appClientId)
    : []

  const { data: productsData } = useSWR(
    selectedAppClientId ? ProductService.getAll(selectedAppClientId) : null
  )
  const products = productsData
    ? ProductService.toRow(productsData).filter(
        (item) =>
          !productLinks.map((e) => e.linked_product_id).includes(item.id) &&
          item.id.toString() != id
      )
    : []

  const handleSubmit = async (values: ProductLinkCreateState) => {
    setLoading(true)
    if (!appClientId || !id) return
    try {
      const { data: result } = await ProductService.addLinkedProduct(
        appClientId,
        id,
        values
      )
      if (result.success) {
        message.success(`Successfully linked reviews`)
        refresh()
        mutateLinks()
        setOpen(false)
      }
    } catch (error: any) {
      message.error(`Failed to link reviews`)
    }
    setLoading(false)
  }

  return (
    <Modal
      open={open}
      onOk={form.submit}
      okButtonProps={{
        loading,
      }}
      onCancel={() => {
        setOpen(false)
        form.resetFields()
      }}
      cancelButtonProps={{
        loading,
      }}
      okText={'Link Product'}
      title='Link Product'
    >
      <Form form={form} onFinish={handleSubmit} layout='vertical'>
        <Form.Item
          name='app_client_id'
          label='Store'
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
          initialValue={appClients[0]?.id ?? null}
        >
          <Select>
            {appClients.map((e) => (
              <Select.Option value={e.id} key={e.id}>
                {e.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {selectedAppClientId && (
          <Form.Item
            name='product_id'
            label='Product'
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <Select>
              {products.map((e) => (
                <Select.Option value={e.id} key={e.id}>
                  {e.name} ({e.id})
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

export default ProductDetail
