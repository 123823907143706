import { Card, Empty, Spin } from 'antd'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'

import tableStore from '../../lib/store/product_table'
import { serialize } from '../../network/request'
import ProductTable from './ProductTable'
import { PageHeader } from '@ant-design/pro-layout'
import appClientStore from '../../lib/store/appClient'
import ProductService from '../../network/services/product'
import { useTranslation } from 'react-i18next'

const ProductPage = () => {
  const { t } = useTranslation()
  return (
    <PageHeader title={t('products')}>
      <Card>
        <ProductTableWrapper />
      </Card>
    </PageHeader>
  )
}

const ProductTableWrapper = () => {
  const {
    state: { id: appClientId },
  } = useSnapshot(appClientStore)
  const { state } = useSnapshot(tableStore)

  // use this to preload
  const { data: response, error } = useSWR(
    serialize(ProductService.getAll(appClientId!), {
      page: state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters,
    })
  )

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return <Spin />
  }

  const { total } = ProductService.toPaginate(response)

  return (
    <>
      <ProductTable total={total} />
      {/* preload next page */}
      {state.currentPage * state.pageSize < total && (
        <div style={{ display: 'none' }}>
          <ProductTable total={total} overridePage={state.currentPage + 1} />
        </div>
      )}
    </>
  )
}

export default ProductPage
