import { useSnapshot } from 'valtio'
import appClientStore from '../lib/store/appClient'
import useSWR from 'swr'
import AppClientService, { AppClient } from '../network/services/appClient'

const useAppClientRole = () => {
  const { state: teamState } = useSnapshot(appClientStore)
  const { data: team } = useSWR<AppClient>(
    teamState?.id ? AppClientService.get(teamState?.id) : null
  )

  if (team) {
    return team.role ?? null
  }

  return null
}

export default useAppClientRole
