import { FC, useEffect, useState } from 'react'
import {
  Table,
  Button,
  Empty,
  Modal,
  Row,
  Col,
  Dropdown,
  Rate,
  message,
  Typography,
} from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../lib/store/review_table'
import TableFilter, { ITableFilter } from '../../components/TableFilter'
import { serialize } from '../../network/request'
import ReviewService, { Review } from '../../network/services/reviews'
import Gaps from '../../components/Gaps'
import appClientStore from '../../lib/store/appClient'
import { green, red } from '@ant-design/colors'
import { useTranslation } from 'react-i18next'

const ReviewTable: FC<{ total: number; overridePage?: number }> = ({
  total,
  overridePage,
}) => {
  const navigate = useNavigate()
  const {
    state: { id: appClientId },
  } = useSnapshot(appClientStore)
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState<any>(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([])
  const [visible, setVisible] = useState(false)
  const [modalType, setModalType] = useState<
    'delete' | 'publish' | 'unpublish' | null
  >(null)

  const { data: response, mutate } = useSWR(
    serialize(ReviewService.getAll(appClientId!), {
      page: overridePage ?? state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters,
    })
  )
  const { t } = useTranslation()
  const rows = ReviewService.toRow(response)

  const handlePublish = async (published: boolean, id: number) => {
    try {
      await ReviewService.update(appClientId!, id, {
        published,
      })
    } catch (error: any) {
      message.error(
        `${published ? 'Publish' : 'Unpublish'} review error ` + error.message
      )
    }
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (keySelected: any[]) => {
      setSelectedRowKeys(keySelected)
    },
  }

  const columns = [
    {
      title: t('title'),
      key: 'title',
      render: (record: Review) => (
        <div style={{ width: 200, maxHeight: 100, overflow: 'hidden' }}>
          <Typography.Text
            style={{
              textOverflow: 'ellipsis',
            }}
          >
            {record.title}
          </Typography.Text>
        </div>
      ),
      sorter: true,
    },
    {
      title: t('content'),
      key: 'content',
      render: (record: Review) => (
        <div style={{ width: 200, maxHeight: 100, overflow: 'hidden' }}>
          <Typography.Text
            style={{
              textOverflow: 'ellipsis',
            }}
          >
            {record.content}
          </Typography.Text>
        </div>
      ),
      sorter: true,
    },
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: t('product'),
      key: 'product',
      render: (record: Review) => record.product.name,
      width: 200,
    },
    {
      title: t('ratings'),
      key: 'score',
      width: 180,
      sorter: true,
      render: (record: Review) => {
        return <Rate disabled value={record.score} />
      },
    },
    {
      title: t('verified'),
      key: 'verified',
      width: 180,
      render: (record: Review) => {
        return record.verifiedEmail ? (
          <Typography.Text style={{ color: green[2] }}>
            {t('verified')}
          </Typography.Text>
        ) : (
          <Typography.Text style={{ color: red[2] }}>
            {t('notVerified')}
          </Typography.Text>
        )
      },
    },
    {
      title: t('createdAt'),
      key: 'created_at',
      width: 180,
      sorter: true,
      render: (record: Review) => {
        return (
          <Typography.Text>
            {new Date(record.created_at).toLocaleString()}
          </Typography.Text>
        )
      },
    },
    {
      title: t('publication'),
      key: 'published',
      width: 180,
      render: (record: Review) => {
        return (
          <Button
            onClick={async (e) => {
              e.stopPropagation()
              await handlePublish(!record.published, record.id)
              await mutate()
            }}
            type={record.published ? 'default' : 'primary'}
            htmlType='button'
          >
            {record.published ? t('unpublish') : t('publish')}
          </Button>
        )
      },
    },
  ]

  // content={
  //   initialValue.variants.length > 0
  //     ? initialValue.variants.map((item) => item.name).join(',')
  //     : '-'
  // }

  const filterColumns: ITableFilter[] = [
    { key: 'title', name: 'Title' },
    { key: 'customer_names', name: 'Customer Name' },
    { key: 'product_names', name: 'Product Name' },
    { key: 'content', name: 'Content' },
    { key: 'order_date', name: 'Order Date' },
  ]
  filterColumns.forEach((item) => {
    if (
      tableStore.state?.filters &&
      Object.keys(tableStore.state.filters).includes(item.key)
    ) {
      item.default_value = tableStore.state.filters[item.key]
      item.default = true
    }
  })

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const handleMenuClick = (e: any) => {
    setVisible(true)
    setModalType(e.key)
  }

  const batchDeleteRows = async () => {
    // try {
    //   for (const id of selectedRowKeys) {
    //     const { data } = await ReviewService.remove(id)
    //     if (data?.success === true) {
    //       message.success(`Delete source success`)
    //     } else {
    //       message.error(`Delete source ${id} failed`)
    //     }
    //     tableStore.state.refresh = Math.random()
    //   }
    //   setVisible(false)
    // } catch (e) {
    //   message.error({ content: e.message, className: 'message-error' })
    // }
  }

  const batchPublishRows = async () => {
    try {
      for (const id of selectedRowKeys) {
        await handlePublish(true, id)
      }
      mutate()
      setVisible(false)
    } catch (e: any) {
      message.error({ content: e.message, className: 'message-error' })
    }
  }

  const batchUnpublishRows = async () => {
    try {
      for (const id of selectedRowKeys) {
        await handlePublish(false, id)
      }
      mutate()
      setVisible(false)
    } catch (e: any) {
      message.error({ content: e.message, className: 'message-error' })
    }
  }

  const ActionModal = () => {
    switch (modalType) {
      case 'delete':
        return (
          <Modal
            title={`レビュー${selectedRowKeys.length}件）を削除しますか？`}
            open={visible}
            onOk={() => {
              batchDeleteRows()
            }}
            onCancel={() => {
              setVisible(false)
            }}
            okText='削除します'
            cancelText='いいえ'
          >
            <Typography.Text style={{ color: 'red' }}>
              削除されたデータは元に戻せません
            </Typography.Text>
          </Modal>
        )
      case 'publish':
        return (
          <Modal
            title={`Publish ${selectedRowKeys.length} reviews`}
            open={visible}
            onOk={() => {
              batchPublishRows()
            }}
            onCancel={() => {
              setVisible(false)
            }}
            okText='Publish'
            cancelText='Cancel'
          ></Modal>
        )

      case 'unpublish':
        return (
          <Modal
            title={`Unpublish ${selectedRowKeys.length} reviews`}
            open={visible}
            onOk={() => {
              batchUnpublishRows()
            }}
            onCancel={() => {
              setVisible(false)
            }}
            okText='Upublish'
            cancelText='Cancel'
          ></Modal>
        )
    }
    return <></>
  }

  return (
    <>
      <ActionModal />

      <Row>
        <Col span={12}>
          {selectedRowKeys?.length > 0 ? (
            <Dropdown
              menu={{
                items: [
                  {
                    label: 'Delete',
                    onClick: handleMenuClick,
                    key: 'delete',
                  },
                  {
                    label: 'Publish',
                    onClick: handleMenuClick,
                    key: 'publish',
                  },
                  {
                    label: 'Unpublish',
                    onClick: handleMenuClick,
                    key: 'unpublish',
                  },
                ],
              }}
              trigger={['click']}
            >
              <Button type='primary'>
                More Actions <CaretDownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <></>
          )}
        </Col>
        <Col span={12}>
          <TableFilter
            dropdowns={[]}
            columns={filterColumns}
            hasDate={false}
            setCurrentFilters={setCurrentFilters}
          />
          <Gaps level={3} />
        </Col>
      </Row>

      {rows?.length > 0 ? (
        <Table
          columns={columns}
          dataSource={rows}
          onChange={(pagination, filters, sorter: any) => {
            tableStore.state.sort =
              sorter != null &&
              !_.isEmpty(sorter?.columnKey) &&
              !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null

            tableStore.state.currentPage = pagination.current ?? 1
            tableStore.state.pageSize = pagination.pageSize ?? 10
          }}
          pagination={{
            showSizeChanger: true,
            total,
            pageSize: state.pageSize,
            current: state.currentPage,
            pageSizeOptions: [10, 20, 50, 100, 200, 300],
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (selectedRowKeys?.length > 0) {
                  if (selectedRowKeys.includes(record.id)) {
                    const filterKeys = _.filter(
                      [...selectedRowKeys],
                      function (o) {
                        return o !== record.id
                      }
                    )
                    setSelectedRowKeys([...filterKeys])
                  } else {
                    setSelectedRowKeys([...selectedRowKeys, record.id])
                  }
                } else {
                  navigate(`${record.id}`)
                }
              }, // click row
            }
          }}
          rowSelection={rowSelection}
        />
      ) : (
        <Empty description={'No record found'} />
      )}
    </>
  )
}

export default ReviewTable
