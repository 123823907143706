import React, { useState, useEffect, FC } from 'react'
import { Space, Card, Typography, Row, Col, Spin, Button } from 'antd'
import { useSnapshot } from 'valtio'
import { Navigate, Link, useSearchParams } from 'react-router-dom'
import ShopifyOauthService from '../../network/services/oauth'
import appClientStore from '../../lib/store/appClient'

const ShopifyOauth: FC = () => {
  const [searchParams] = useSearchParams()
  const [redirectToReferrer, setRedirectToReferrer] = useState(false)
  const [error, setError] = useState('')
  const [redirectCountDown, setRedirectCountDown] = useState(5)
  const [countDownInterval, setCountDownInterval] =
    useState<null | NodeJS.Timer>(null)
  const [authSuccess, setAuthSuccess] = useState(false)

  useEffect(() => {
    //if the user has logged in, create a new app client
    const createAppClient = async () => {
      if (authSuccess) return
      try {
        const { data: result } = await ShopifyOauthService.auth({
          code: searchParams.get('code') ?? '',
          hmac: searchParams.get('hmac') ?? '',
          shop: searchParams.get('shop') ?? '',
          state: searchParams.get('state') ?? '',
          timestamp: searchParams.get('timestamp') ?? '',
        })
        if (result.success) {
          appClientStore.state.id = result.id
          appClientStore.state.name = result.name
          setAuthSuccess(true)
          const interval = setInterval(() => {
            setRedirectCountDown((prev) => (prev - 1 > 0 ? prev - 1 : 0))
          }, 1000)
          setCountDownInterval(interval)
        }

        setError('認証が失敗しました。')
      } catch (e) {
        setError('認証が失敗しました。' + JSON.stringify(e))
      }
    }

    createAppClient()
  })

  useEffect(() => {
    if (redirectCountDown <= 0 && countDownInterval) {
      clearInterval(countDownInterval)
      setCountDownInterval(null)
      setRedirectToReferrer(true)
    }
  }, [redirectCountDown])

  if (redirectToReferrer) {
    return (
      <Navigate
        to={{
          pathname: '/dashboard',
        }}
      />
    )
  }

  return (
    <Row
      justify='center'
      align='middle'
      style={{ height: '100vh', width: '100%' }}
    >
      <Col>
        <Space direction='vertical'>
          <Link to='/dashboard'>
            <div
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <Typography.Title>Solutions Reviews</Typography.Title>
            </div>
          </Link>

          {error && !authSuccess ? (
            <Card style={{ minWidth: '400px' }}>
              <Row
                justify='center'
                align='middle'
                style={{ padding: '24px', width: '100%' }}
              >
                <Col>
                  <Space direction='vertical' align='center'>
                    <Typography.Text>{error}</Typography.Text>
                    <Button
                      type='primary'
                      onClick={() => setRedirectToReferrer(true)}
                    >
                      ホームページに戻る
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Card>
          ) : authSuccess ? (
            <Card style={{ minWidth: '400px' }}>
              <Row
                justify='center'
                align='middle'
                style={{ padding: '24px', width: '100%' }}
              >
                <Col>
                  <Space direction='vertical' align='center'>
                    <Typography.Text>認証が完了しました。</Typography.Text>
                    <Typography.Text>
                      あと{redirectCountDown}秒でホームページに戻ります。
                    </Typography.Text>
                  </Space>
                </Col>
              </Row>
            </Card>
          ) : (
            <Card style={{ minWidth: '400px' }}>
              <Row
                justify='center'
                align='middle'
                style={{ padding: '24px', width: '100%' }}
              >
                <Col>
                  <Space direction='vertical' align='center'>
                    <Typography.Text>認証中…</Typography.Text>
                    <Spin />
                  </Space>
                </Col>
              </Row>
            </Card>
          )}
        </Space>
      </Col>
    </Row>
  )
}

export default ShopifyOauth
