import { Typography } from 'antd'
import React from 'react'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'

export default function ErrorPage() {
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <Typography.Title title='h1'>Oops!</Typography.Title>
        <Typography.Title title='h2'>{error.status}</Typography.Title>
        <Typography.Text>{error.statusText}</Typography.Text>
        {error.data?.message && <p>{error.data.message}</p>}
      </div>
    )
  } else {
    return <Typography.Title title='h1'>Oops</Typography.Title>
  }
}
