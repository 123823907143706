import React, { FC, useCallback, useMemo, useState } from 'react'
import {
  Card,
  Col,
  Form,
  Row,
  message,
  Button,
  Image,
  Divider,
  Typography,
  Upload,
  FormInstance,
  Rate,
  Input,
  InputNumber,
  Select,
} from 'antd'

import ReviewService, {
  Review,
  ReviewUpdateState,
} from '../../network/services/reviews'

import Gaps from '../../components/Gaps'
import _ from 'lodash'
import { PlusOutlined } from '@ant-design/icons'
import ReadOnlyFormItem from '../../components/ReadOnlyFormItem'
import Link from 'antd/es/typography/Link'
import appClientStore from '../../lib/store/appClient'
import { useSnapshot } from 'valtio'
import { green, red } from '@ant-design/colors'
import { useTranslation } from 'react-i18next'
// import { useMediaQuery } from 'react-responsive'

const ReviewForm: FC<{
  form: FormInstance<ReviewUpdateState>
  initialValue: Review
  refresh: () => any
}> = ({ form, initialValue, refresh }) => {
  const {
    state: { id: appClientId },
  } = useSnapshot(appClientStore)
  const [language, setLanguage] = useState<'en' | 'ko' | 'zh' | 'zh-TW' | 'ja'>(
    initialValue.translation.original
  )
  const { t } = useTranslation()

  const languages = useMemo<
    {
      value: 'en' | 'ko' | 'zh' | 'zh-TW' | 'ja'
      label: string
    }[]
  >(
    () => [
      {
        label: t('english'),
        value: 'en',
      },
      {
        label: t('korean'),
        value: 'ko',
      },
      {
        label: t('chineseCn'),
        value: 'zh',
      },
      {
        label: t('chineseTw'),
        value: 'zh-TW',
      },
      {
        label: t('japanese'),
        value: 'ja',
      },
    ],
    [t]
  )

  const handlePublish = useCallback(
    async (published: boolean) => {
      try {
        const { data: result } = await ReviewService.update(
          appClientId!,
          initialValue.id,
          {
            published,
          }
        )
        if (result.success) {
          message.success(
            `Successfully ${published ? 'published' : 'unpublished'} review`
          )
          refresh()
        }
      } catch (error: any) {
        message.error(
          `${published ? 'Publish' : 'Unpublish'} review error ` + error.message
        )
      }
    },
    [appClientId]
  )

  const handleSubmit = useCallback(
    async (values: ReviewUpdateState) => {
      try {
        const { data: result } = await ReviewService.update(
          appClientId!,
          initialValue.id,
          values
        )
        if (result.success) {
          message.success(`Successfully updated review`)
          refresh()
        }
      } catch (error: any) {
        message.error(`Update review error ` + error.message)
      }
    },
    [appClientId]
  )

  return (
    <>
      <Form form={form} onFinish={handleSubmit} layout='vertical'>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card bordered={false}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Select
                  value={language}
                  onChange={(value) => {
                    setLanguage(value)
                  }}
                  style={{ width: 200, textAlign: 'center' }}
                  options={languages}
                />
              </div>
              <Gaps />
              <ReadOnlyFormItem
                title='Score'
                enableCopy={false}
                content={<Rate disabled value={initialValue.score} />}
              />
              <ReadOnlyFormItem title='Name' content={initialValue.name} />
              <ReadOnlyFormItem title='Email' content={initialValue.email} />
              <ReadOnlyFormItem
                title='Title'
                content={
                  language == initialValue.translation.original ||
                  !initialValue.translation[language]?.title
                    ? initialValue.title
                    : initialValue.translation[language]?.title
                }
              />
              <ReadOnlyFormItem
                title='Content'
                content={
                  language == initialValue.translation.original
                    ? initialValue.content
                    : initialValue.translation[language]?.content
                }
              />
              <ReadOnlyFormItem
                title='Created At'
                content={new Date(initialValue.created_at).toLocaleString()}
              />
              <ReadOnlyFormItem
                title='Product'
                enableCopy={false}
                content={
                  <Row align='middle' gutter={[16, 16]}>
                    <Col>
                      <Image
                        src={initialValue.product.image_url}
                        width={64}
                        fallback='/no-image.jpg'
                        style={{ objectFit: 'contain' }}
                      />
                    </Col>
                    <Col>
                      <Link href={initialValue.product.page_url} target='blank'>
                        {initialValue.product.name}
                      </Link>
                    </Col>
                  </Row>
                }
              />

              <ReadOnlyFormItem
                title='Variants'
                enableCopy={false}
                content={
                  initialValue.variants.length > 0
                    ? initialValue.variants.map((item) => item.name).join(',')
                    : '-'
                }
              />

              <ReadOnlyFormItem
                title='Verified'
                enableCopy={false}
                content={
                  initialValue.verifiedEmail ? (
                    <Typography.Text style={{ color: green[2] }}>
                      Verified
                    </Typography.Text>
                  ) : (
                    <Typography.Text style={{ color: red[2] }}>
                      Not verified
                    </Typography.Text>
                  )
                }
              />

              <ReadOnlyFormItem
                title='Imported At'
                content={
                  initialValue.imported_at
                    ? new Date(initialValue.imported_at).toLocaleString()
                    : null
                }
              />

              <ReadOnlyFormItem
                title='External Import ID'
                content={initialValue.external_import_id}
              />

              <ReadOnlyFormItem
                title='Votes'
                content={
                  <div style={{ display: 'flex' }}>
                    <Card>
                      <ReadOnlyFormItem
                        title='Up votes'
                        enableCopy={false}
                        content={initialValue.up_votes}
                      />
                      <div style={{ display: 'flex' }}>
                        <Form.Item name='up_votes_offset'>
                          <InputNumber
                            defaultValue={initialValue.up_votes_offset}
                            step={1}
                            width={100}
                          />
                        </Form.Item>
                        <Gaps />
                        <Button onClick={form.submit}>Adjust offset</Button>
                      </div>
                    </Card>
                    <Gaps />
                    <Card>
                      <ReadOnlyFormItem
                        title='Down votes'
                        enableCopy={false}
                        content={initialValue.down_votes}
                      />
                      <div style={{ display: 'flex' }}>
                        <Form.Item name='down_votes_offset'>
                          <InputNumber
                            defaultValue={initialValue.down_votes_offset}
                            step={1}
                            width={100}
                          />
                        </Form.Item>
                        <Gaps />
                        <Button onClick={form.submit}>Adjust offset</Button>
                      </div>
                    </Card>
                  </div>
                }
              />

              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  onClick={() => handlePublish(!initialValue.published)}
                  type={initialValue.published ? 'default' : 'primary'}
                  htmlType='button'
                >
                  {initialValue.published ? 'Unpublish' : 'Publish'}
                </Button>
              </div>

              <Divider />
              <Typography.Text style={{ fontWeight: 'bold' }}>
                Uploads :
              </Typography.Text>
              <Gaps />
              {initialValue?.uploads && (
                <>
                  <Row gutter={[16, 16]}>
                    {initialValue.uploads.map((item) => (
                      <Col span={4}>
                        <div style={{ width: '100%', position: 'relative' }}>
                          <div
                            style={{
                              position: 'absolute',
                              zIndex: 2,
                              right: 0,
                            }}
                          ></div>
                        </div>
                        <Image src={item.url} />
                      </Col>
                    ))}
                  </Row>
                </>
              )}
            </Card>
          </Col>
        </Row>
        <Gaps />
      </Form>
    </>
  )
}

export default ReviewForm
