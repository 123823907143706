import { useNavigate, useParams } from 'react-router-dom'
import { Form, Empty, Spin } from 'antd'
import useSWR from 'swr'
import appClientStore from '../../lib/store/appClient'
import ReviewService, {
  Review,
  ReviewUpdateState,
} from '../../network/services/reviews'
import { PageHeader } from '@ant-design/pro-layout'
import ReviewForm from './ReviewForm'
import { useSnapshot } from 'valtio'

const ReviewDetail = () => {
  const navigate = useNavigate()
  const {
    state: { id: appClientId },
  } = useSnapshot(appClientStore)
  const { id } = useParams()

  const [form] = Form.useForm<ReviewUpdateState>()

  // use this to preload
  const {
    data: response,
    error,
    mutate,
  } = useSWR<Review>(ReviewService.get(appClientId!, id!))

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return (
      <PageHeader>
        <Spin />
      </PageHeader>
    )
  }

  const refresh = () => {
    if (id != null) {
      if (id === 'new') {
      } else {
        mutate()
      }
    }
  }

  return (
    <PageHeader
      title='Manage Review'
      onBack={() => navigate('/dashboard/reviews')}
    >
      <ReviewForm form={form} initialValue={response} refresh={refresh} />
    </PageHeader>
  )
}

export default ReviewDetail
