import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { SWRConfig } from 'swr'
import { fetcher } from '../src/network/request'
import App from './App'
import '@fontsource/noto-sans-jp'
import { ConfigProvider } from 'antd'
import theme from './theme'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  //<React.StrictMode>
  <ConfigProvider theme={theme}>
    <SWRConfig
      value={{ fetcher, revalidateOnFocus: false, errorRetryCount: 3 }}
    >
      <App />
    </SWRConfig>
  </ConfigProvider>
  //</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
