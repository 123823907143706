import { Card, Empty, Spin } from 'antd'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'

import tableStore from '../../lib/store/review_request_table'
import { serialize } from '../../network/request'
import { PageHeader } from '@ant-design/pro-layout'
import appClientStore from '../../lib/store/appClient'
import ReviewRequestService from '../../network/services/reviewRequests'
import ReviewRequestTable from './ReviewRequestTable'
import { useTranslation } from 'react-i18next'

const ReviewRequestPage = () => {
  const { t } = useTranslation()
  return (
    <PageHeader title={t('requests')}>
      <Card>
        <ReviewRequestTableWrapper />
      </Card>
    </PageHeader>
  )
}

const ReviewRequestTableWrapper = () => {
  const {
    state: { id: appClientId },
  } = useSnapshot(appClientStore)
  const { state } = useSnapshot(tableStore)

  // use this to preload
  const { data: response, error } = useSWR(
    serialize(ReviewRequestService.getAll(appClientId!), {
      page: state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters,
    })
  )

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return <Spin />
  }

  const { total } = ReviewRequestService.toPaginate(response)

  return (
    <>
      <ReviewRequestTable total={total} />
      {/* preload next page */}
      {state.currentPage * state.pageSize < total && (
        <div style={{ display: 'none' }}>
          <ReviewRequestTable
            total={total}
            overridePage={state.currentPage + 1}
          />
        </div>
      )}
    </>
  )
}

export default ReviewRequestPage
