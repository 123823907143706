import React, { useEffect } from 'react'
import logo from './logo.svg'
import './App.css'
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
  Outlet,
} from 'react-router-dom'
import RequireAuth from './layouts/RequireAuth'
import AuthLayout from './layouts/AuthLayout'
import ErrorPage from './pages/ErrorPage'
import ReviewPage from './routes/Reviews'
import Login from './routes/Login'
import { ConfigProvider } from 'antd'
import theme from './theme'
import ReviewDetail from './routes/ReviewDetail'
import ReviewRequestPage from './routes/ReviewRequests'
import SettingsPage from './routes/Settings'
import EmailReview from './routes/EmailReview'
import CollaboratorPage from './routes/Collaborators'
import CollaboratorDetail from './routes/CollaboratorDetail'
import ShopifyOauth from './routes/ShopifyOauth'
import CasefiniteEmailReview from './routes/CasefiniteEmailReview'
import ProductPage from './routes/Products'
import ProductDetail from './routes/ProductDetail'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'
import languageStore from './lib/store/language'
import './i18n'
import DashboardPage from './routes/Dashboard'

const router = createBrowserRouter([
  // authenticated layout
  {
    path: 'dashboard',
    element: (
      <RequireAuth>
        <AuthLayout />
      </RequireAuth>
    ),

    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <DashboardPage />,
      },
      {
        path: 'reviews',
        children: [
          {
            path: '',
            element: <ReviewPage />,
          },
          {
            path: ':id',
            element: <ReviewDetail />,
          },
        ],
      },
      {
        path: 'products',
        children: [
          {
            path: '',
            element: <ProductPage />,
          },
          {
            path: ':id',
            element: <ProductDetail />,
          },
        ],
      },
      {
        path: 'review-requests',
        children: [
          {
            path: '',
            element: <ReviewRequestPage />,
          },
        ],
      },
      {
        path: 'collaborators',
        children: [
          {
            path: '',
            element: <CollaboratorPage />,
          },
          {
            path: ':id',
            element: <CollaboratorDetail />,
          },
        ],
      },
      {
        path: 'settings',
        children: [
          {
            path: '',
            element: <SettingsPage />,
          },
        ],
      },
      {
        //for invalid path, redirect to /
        path: ':any',
        element: <Navigate to='/' />,
      },
    ],
  },
  //oauth2
  {
    path: 'oauth2',
    element: (
      <RequireAuth>
        <Outlet />
      </RequireAuth>
    ),
    children: [
      {
        path: 'shopify',
        element: <ShopifyOauth />,
      },
    ],
    errorElement: <ErrorPage />,
  },
  // public layout
  {
    path: '',
    children: [
      {
        path: '',
        element: <Login />,
      },
      {
        path: 'email-review/:id',
        element: <EmailReview />,
      },
      {
        path: 'casefinite-email-review/:id',
        element: <CasefiniteEmailReview />,
      },
    ],
    errorElement: <ErrorPage />,
  },
])

function App() {
  const { i18n } = useTranslation()
  const {
    state: { language },
  } = useSnapshot(languageStore)

  useEffect(() => {
    if (i18n.changeLanguage && language) {
      i18n.changeLanguage(language)
    }
  }, [language, i18n])

  return (
    <ConfigProvider theme={theme}>
      <RouterProvider router={router} />
    </ConfigProvider>
  )
}

export default App
