import { PageHeader } from '@ant-design/pro-layout'
import { Card, Spin, Tooltip, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import StatisticsService, {
  AverageRatingStat,
} from '../../network/services/statistics'
import { IDataResponse, serialize } from '../../network/request'
import { useSnapshot } from 'valtio'
import appClientStore from '../../lib/store/appClient'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import Gaps from '../../components/Gaps'
import AverageRatingTable from './AverageRatingTable'

const DashboardPage = () => {
  const { t } = useTranslation()
  const {
    state: { id: appClientId },
  } = useSnapshot(appClientStore)
  const {
    data: top10Ratings,
    isLoading,
    error,
  } = useSWR<IDataResponse<AverageRatingStat>>(
    appClientId
      ? serialize(StatisticsService.getAll(appClientId), {
          limit: 5,
          page: 1,
          sort: 'avg_rating:desc',
        })
      : null
  )

  if (error) {
    return (
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        Error {error.message}
      </div>
    )
  }

  if (isLoading) {
    return (
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <Spin />
      </div>
    )
  }
  return (
    <PageHeader title={t('dashboard')}>
      <Card>
        <Typography.Text style={{ fontWeight: 'bold' }}>
          {t('reviewRatingTopNumber', { value: 5 })}
        </Typography.Text>

        <Gaps />

        <div
          style={{
            width: '100%',
            height: 300,
          }}
        >
          <ResponsiveContainer width='100%' height='100%'>
            <BarChart
              width={500}
              height={300}
              data={top10Ratings?.data.map((item) => ({
                name: item.product.name,
                avg_rating: parseFloat(item.avg_rating),
              }))}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='name' />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey='avg_rating'
                fill='purple'
                activeBar={<Rectangle fill='gold' stroke='purple' />}
                name={t('averageRatings')}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Card>

      <Gaps />

      <AverageRatingTable />
    </PageHeader>
  )
}

export default DashboardPage
