import { User } from './auth'
import client, { IDataResponse } from '../request'
import { Product } from './product'
import { Review } from './reviews'

export enum Roles {
  ADMIN = 'admin',
  STAFF = 'staff',
}

export interface Collaborator {
  id: number
  role: Roles
  user: User
}

export interface CollaboratorCreateState {
  role: Roles
  user: User
}

const getAll = (appClientId: string) => {
  return `/admin/app-clients/${appClientId}/collaborators`
}

const get = (appClientId: string, id: string | number) => {
  return `/admin/app-clients/${appClientId}/collaborators/${id}`
}

const create = (appClientId: string, data: CollaboratorCreateState) => {
  return client.post(`/admin/app-clients/${appClientId}/collaborators`, data)
}

const update = (
  appClientId: string,
  id: string | number,
  data: CollaboratorCreateState
) => {
  return client.put(
    `/admin/app-clients/${appClientId}/collaborators/${id}`,
    data
  )
}

const remove = (appClientId: string, id: string | number) => {
  return client.delete(`/admin/app-clients/${appClientId}/collaborators/${id}`)
}

const toRow = (data: IDataResponse<Collaborator>) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id,
      }
    })
  }

  return []
}

const toPaginate = (data: IDataResponse<Collaborator>) => {
  return {
    total: data?.meta?.total ?? 0,
  }
}

const CollaboratorService = {
  getAll,
  get,
  toRow,
  create,
  update,
  toPaginate,
  remove,
}

export default CollaboratorService
