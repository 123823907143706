import { useNavigate, useParams } from 'react-router-dom'
import { Form, Empty, Spin, Button, Typography } from 'antd'
import useSWR from 'swr'
import appClientStore from '../../lib/store/appClient'
import { PageHeader } from '@ant-design/pro-layout'
import SettingsForm from './SettingsForm'
import { useSnapshot } from 'valtio'
import AppSettingsService, {
  AppSettingUpdateState,
} from '../../network/services/settings'
import { useCallback, useMemo, useState } from 'react'
import Gaps from '../../components/Gaps'
import DomainSettingForm from './DomainSettingForm'

const SettingsPage = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {
    state: { id: appClientId },
  } = useSnapshot(appClientStore)
  const { id } = useParams()

  const [form] = Form.useForm<AppSettingUpdateState>()

  // use this to preload
  const {
    data: response,
    error,
    mutate,
  } = useSWR(AppSettingsService.get(appClientId!))

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return (
      <PageHeader>
        <Spin />
      </PageHeader>
    )
  }

  const refresh = async () => {
    if (id != null) {
      await mutate()
    }
  }

  return (
    <PageHeader title='Settings' onBack={() => navigate('/dashboard/reviews')}>
      <SettingsForm
        form={form}
        initialValue={response}
        refresh={refresh}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <Gaps level={2} />
      <DomainSettingForm initialValue={response} refresh={refresh} />
    </PageHeader>
  )
}

export default SettingsPage
