export interface EmailTemplate {
  id: string
  name: string
}

const getAll = '/admin/email-templates'

const EmailTemplateService = {
  getAll,
}

export default EmailTemplateService
