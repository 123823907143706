import React, { FC, useCallback } from 'react'
import {
  Card,
  Col,
  Form,
  Row,
  message,
  Button,
  Image,
  Divider,
  Typography,
  FormInstance,
  Rate,
  InputNumber,
  Table,
} from 'antd'
import appClientStore from '../../lib/store/appClient'

import Gaps from '../../components/Gaps'
import _ from 'lodash'
import ReadOnlyFormItem from '../../components/ReadOnlyFormItem'
import ProductService, {
  Product,
  ProductLink,
  ProductVariant,
} from '../../network/services/product'
import { useSnapshot } from 'valtio'
import { useParams } from 'react-router-dom'
import useSWR, { KeyedMutator } from 'swr'
import { serialize } from '../../network/request'
// import { useMediaQuery } from 'react-responsive'

const ProductForm: FC<{
  initialValue: Product
  refresh: () => any
}> = ({ initialValue, refresh }) => {
  const {
    state: { id: appClientId },
  } = useSnapshot(appClientStore)
  const { id } = useParams()
  const { data: productLinksData, mutate: mutateLinks } = useSWR(
    serialize(ProductService.getLinkedProducts(appClientId!, id!), {
      limit: 100,
    })
  )
  const productLinks = ProductService.productLinkToRow(productLinksData)

  return (
    <>
      <Form layout='vertical'>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card bordered={false}>
              <ReadOnlyFormItem title='ID' content={initialValue.id} />
              <ReadOnlyFormItem title='Name' content={initialValue.name} />
              <ReadOnlyFormItem
                title='Shopify ID'
                content={initialValue.custom_id}
              />
              <Divider />
              <Typography.Text style={{ fontWeight: 'bold' }}>
                Linked Reviews
              </Typography.Text>
              <Gaps />
              <LinkedProductsList data={productLinks} mutate={mutateLinks} />
            </Card>
            <Gaps />
            {initialValue.variants && (
              <ProductVariantTable data={initialValue.variants} />
            )}
          </Col>
        </Row>
        <Gaps />
      </Form>
    </>
  )
}

const LinkedProductsList = ({
  data,
  mutate,
}: {
  data: ProductLink[]
  mutate: KeyedMutator<ProductLink>
}) => {
  const {
    state: { id: appClientId },
  } = useSnapshot(appClientStore)
  const { id: productId } = useParams()

  const handleRemovLink = async (id: number) => {
    if (!appClientId || !productId) return
    try {
      const { data: result } = await ProductService.deleteLinkedProduct(
        appClientId,
        productId,
        id
      )
      if (result.success) {
        message.success(`Successfully remove linkage`)
        mutate()
      }
    } catch (error: any) {
      message.error(`Failed remove linkage`)
    }
  }

  const columns = [
    {
      title: 'Store',
      key: 'store',
      render: (record: ProductLink) => {
        return record.linked_product.app_client?.name ?? ''
      },
      sorter: true,
    },
    {
      title: 'Name',
      key: 'name',
      render: (record: ProductLink) => {
        return record.linked_product.name
      },
      sorter: true,
    },
    {
      title: 'Remove Linkage',
      key: 'published',
      width: 180,
      render: (record: ProductLink) => {
        return (
          <Button
            onClick={async (e) => {
              e.stopPropagation()
              await handleRemovLink(record.id)
            }}
            type='primary'
            htmlType='button'
          >
            Remove
          </Button>
        )
      },
    },
  ]
  return <Table columns={columns} dataSource={data} />
}

const ProductVariantTable = ({ data }: { data: ProductVariant[] }) => {
  const columns = [
    {
      title: 'Shopify ID',
      dataIndex: 'custom_id',
      key: 'custom_id',
      sorter: true,
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      sorter: true,
    },
    {
      title: 'Name',
      dataIndex: 'fullname',
      key: 'fullname',
      sorter: true,
    },
  ]

  return <Table columns={columns} dataSource={data} />
}

export default ProductForm
