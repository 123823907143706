import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Typography,
  UploadFile,
  message,
  Divider,
  Radio,
} from 'antd'

import TextArea from 'antd/es/input/TextArea'
import React, { FC, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import ReviewService from '../network/services/reviews'
import { UploadChangeParam } from 'antd/es/upload'
import useSWR from 'swr'
import ReviewRequestService, {
  ReviewRequest,
} from '../network/services/reviewRequests'
import { ReviewRequestStatus } from '../network/services/reviewRequests'

const { Link } = Typography

const CasefiniteEmailReview: FC = () => {
  return (
    <Row justify='center' align='middle' style={{ backgroundColor: '#efeef1' }}>
      <Col>
        <EmailReviewForm />
      </Col>
    </Row>
  )
}

const EmailReviewForm: FC = () => {
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const name = searchParams.get('name') ?? ''
  const title = searchParams.get('title') ?? ''
  const content = searchParams.get('content') ?? ''

  // const product = {
  //   name: 'THE FROST AIR ULTRA - iPhone 14 Pro - アイスホワイト',
  //   pageUrl: 'https://u17153129.ct.sendgrid.net/ls/click?upn=U5YN2fme-2Bws9J6GS5qjaYNpthvE-2BEjRXS4-2FMbFJEWL61y086XkNq5baBRVTVmxPwM2a4Ka3tjig2AX4pZOwOwA-3D-3Du5Ny_5llLShUBmXbOvBYiYwIrQFdqe3Qy1-2Bqjk0jcktgZ5EeWWXAxTq71HkFigzQfVQD5uoRhv3gCu4SdVnq5tEA4DGxTncuGgihchiRl1-2FdC-2BJjJx72KYr59wHDwmIJzsFHOPHDllInvZ3-2BAGBKU3Jvv6NaHSSRpjs8qfY-2FUsZPnZUrfTzMgoD4lcJOApBJZEEHR5Os2qXr5JbO7KnwmmRIrhg-3D-3D',
  //   imageUrl: 'https://ci4.googleusercontent.com/proxy/CmPZWAVfblvl0Ao4pYKSzQ8mckc6ZYmRBdEw-Mpkzrwr4b9mhtIE0nm1dl0C3hdVkbohnf74q8W-YxLtsU2EfVLHBQNnQMEZrKtd3eWrkuZm7U7UxeQWWTTDM6zy0ZYXsKSCA3k=s0-d-e1-ft#https://cdn.shopify.com/s/files/1/0607/9928/7464/products/fbk_gud.png?v=1648194010',
  // } // temp placeholder

  // // const { data, error, mutate } = useSWR<ReviewRequest>(
  // //   id && token ? ReviewRequestService.publicGet(id, token) : null
  // // )
  // const data = {
  //   "id": 4,
  //   "external_order_id": "5155367354536",
  //   "external_order_name": "#1104",
  //   "product_id": 48,
  //   "email": "limbenny22@gmail.com",
  //   "expires_at": null,
  //   "created_at": "2023-05-29T11:42:06.000+08:00",
  //   "updated_at": "2023-08-25T12:08:38.000+08:00",
  //   "status": "sent",
  //   // "status": "reviewed",
  //   "sent_at": "2023-08-25T12:08:38.000+08:00",
  //   "scheduled_at": null
  // } // temp data

  const { data, error, mutate } = useSWR<ReviewRequest>(
    id && token ? ReviewRequestService.publicGet(id, token) : null
  )

  const handleSubmit = async (values: {
    title?: string
    content: string
    score: number
  }) => {
    if (!id || !token) return
    setLoading(true)
    try {
      const { data: response } = await ReviewService.publicEmailCreate({
        token,
        email: data!.email,
        attachments: fileList.map((item) => item.originFileObj!),
        name,
        ...values,
      })
      if (response.success) {
        await mutate()
      }
    } catch (e: any) {
      message.error(e.message)
    }
    setLoading(false)
  }

  const handleFileChange = (upload: UploadChangeParam<UploadFile>) => {
    setFileList(upload.fileList)
  }

  if (!token) {
    return (
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        Error invalid URL
      </div>
    )
  }

  if (error) {
    return (
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        Error {error.message}
      </div>
    )
  }

  if (!data) {
    return (
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <Spin />
      </div>
    )
  }

  return (
    <>
      <div>
        <div style={style.container}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              width={180}
              src='/casefiniteLogo.svg'
              style={{ objectFit: 'contain' }}
              alt='CASEFINITE'
            />
          </div>
          <Divider style={style.hrStyle} />
          <img style={style.bigImage} src='/bigImage.png' alt='bigImage' />
          <div style={style.content}>
            {data.status == ReviewRequestStatus.REVIEWED ? (
              <>
                <p style={style.title}>ご投稿ありがとうございました！</p>
                <div>
                  <p style={style.normal}>
                    レビューをご記入いただきまして誠にありがとうございます。お客様よりいただきました貴重なご意見やご感想は、今後の運営の参考とさせていただきます。今後とも引き続きご愛顧のほどよろしくお願い申し上げます。
                    <br />
                    <br />
                    ※大変恐れ入りますが、
                    システムによりレビューが反映されるまでにお時間を頂戴しております。予めご了承くださいますようお願い申し上げます。
                    <br />
                    <br />
                  </p>
                </div>
              </>
            ) : (
              <>
                <p style={style.title}>
                  ご利用いただきありがとうございました！
                </p>
                <div>
                  <p style={style.normal}>
                    よろしければ、お客さまが感じられたことや、実際に使用してみて思ったこと、どのようなシチュエーションで利用されているか、おすすめの使い方など、レビューを通して教えていただけると、大変嬉しく思います。
                    <br />
                    <br />
                    お客さまからいただくレビューは、私たちにとって、さらなる製品の改善にダイレクトにつながります。また、商品の購入を検討されている他のお客さまにとっても非常に有益なものです。
                    <br />
                    ぜひ下記のフォームより、レビューをご投稿いただければと思います。
                    <br />
                    <br />※ レビューは匿名投稿になります。
                  </p>
                </div>
                <p style={style.subtitle}>ご購入いただいた商品はこちらです。</p>
                <Link href={data.product.page_url} style={style.productName}>
                  {data.product.name}
                </Link>
                <div style={style.formStyle}>
                  <Form
                    autoComplete='off'
                    form={form}
                    onFinish={handleSubmit}
                    layout='vertical'
                    requiredMark={false}
                  >
                    <Space align='center' size={21}>
                      <img
                        src={data.product.image_url}
                        alt={data.product.name}
                        style={style.productImage}
                      />
                      <Form.Item name='score' initialValue={5}>
                        <Radio.Group>
                          <Space direction='vertical'>
                            <Radio value={5} style={style.reviewStars}>
                              ★★★★★
                            </Radio>
                            <Radio value={4} style={style.reviewStars}>
                              ★★★★
                            </Radio>
                            <Radio value={3} style={style.reviewStars}>
                              ★★★
                            </Radio>
                            <Radio value={2} style={style.reviewStars}>
                              ★★
                            </Radio>
                            <Radio value={1} style={style.reviewStars}>
                              ★
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </Space>
                    <Form.Item
                      name='title'
                      label='レビューのタイトル'
                      initialValue={title}
                      style={style.textInputContainer}
                    >
                      <Input style={{ borderRadius: 0 }} />
                    </Form.Item>
                    <Form.Item
                      label='商品を使った感想、どのようなシチュエーションで利用されているか、おすすめの使い方などを教えてください'
                      name='content'
                      initialValue={content}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your review',
                        },
                      ]}
                      style={style.textInputContainer}
                    >
                      <TextArea
                        autoComplete='off'
                        rows={4}
                        style={{ borderRadius: 0 }}
                      />
                    </Form.Item>
                    <Button
                      style={style.button}
                      type='primary'
                      htmlType='submit'
                      loading={loading}
                    >
                      投稿する
                    </Button>
                  </Form>
                </div>
              </>
            )}
          </div>
          <div style={style.footer}>
            <Space size={36}>
              <img
                width='100%'
                style={style.footerImage}
                src='/footerImage1.png'
                alt='footer-Image1'
              />
              <img
                width='100%'
                style={style.footerImage}
                src='/footerImage2.png'
                alt='footer-Image2'
              />
            </Space>
            <Divider style={{ ...style.hrStyle, margin: '28px 0 24px' }} />
            <p style={style.footerTitle}>
              #CASEFINITE on Instagram and Twitter
            </p>
            <div style={{ margin: '18.5px 0 17.5px 0' }}>
              <a href='https://twitter.com/casefinitejapan'>
                <img style={style.socialIcon} src='/twitterIcon.svg' />
              </a>
              <a href='https://www.instagram.com/casefinitejapan'>
                <img style={style.socialIcon} src='/instagramIcon.svg' />
              </a>
            </div>
            <a href='https://casefinite.jp' style={style.footerLink}>
              https://casefinite.jp
            </a>
            <div style={{ margin: '18.5px auto 0', width: '100%' }}>
              {/* <Row gutter={30} style={{ textAlign: 'left' }} align='middle'> */}
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ marginRight: 16 }}>
                  <img
                    src='/casefiniteFooterLogo.svg'
                    alt='CASEFINITE'
                    height={28}
                  />
                  <p style={style.footerSmallText}>
                    スマホアクセサリーブランド
                  </p>
                </div>

                <div>
                  <img
                    src='/ergofiniteFooterLogo.svg'
                    alt='Ergofinite'
                    height={28}
                  />
                  <p style={style.footerSmallText}>
                    ワークライフスタイルブランド
                  </p>
                </div>
              </div>
              {/* </Row> */}
            </div>
            <Divider style={{ ...style.hrStyle, marginTop: '19px' }} />
          </div>
        </div>
      </div>
    </>
  )
}

export default CasefiniteEmailReview
const style: Record<string, React.CSSProperties> = {
  main: {
    backgroundColor: '#efeef1',
    fontFamily: "'Noto Sans JP', sans-serif",
    margin: '10px auto',
  },
  container: {
    minWidth: '400px',
    maxWidth: '600px',
    backgroundColor: '#ffffff',
    display: 'block',
  },
  // viewInBrowser: {
  //   margin: '8.5px 0 9.5px',
  //   fontFamily: '"Helvetica Neue", sans-serif',
  //   textAlign: 'center',
  //   color: '#727272',
  //   fontWeight: '400',
  //   fontSize: '10px',
  //   lineHeight: '13px'
  // },
  // viewInBrowserLink: {
  //   textDecoration: 'underline',
  //   color: '#1155CC',
  // },
  bigImage: {
    display: 'block',
    objectFit: 'contain',
    margin: '12px auto 29px',
    width: '100%',
    // width: '573px',
    // height: '325px',
  },
  content: {
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  title: {
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '18px',
    margin: '0',
  },
  normal: {
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '17.38px',
  },
  subtitle: {
    fontWeight: '700',
    fontSize: '12px',
    margin: '29px 0 0 0',
  },
  productName: {
    fontWeight: '700',
    fontSize: '12px',
    margin: '6px 0 0 0',
    color: '#4E727D',
  },
  productImage: {
    width: '200px',
    paddingBottom: '22px',
  },
  formStyle: {
    marginTop: '14px',
  },
  reviewStarsContainer: {
    verticalAlign: 'center',
    paddingLeft: '21px',
  },
  reviewStarDiv: {
    margin: '5px 0',
  },
  reviewStars: {
    marginLeft: '8px',
    fontSize: '16px',
    color: '#E9BD23',
  },
  textInputContainer: {
    // width: '472px',
    width: '100%',
  },
  textInput: {
    width: '100%',
    border: 'solid 1px #D9D9D9',
    resize: 'none',
    marginTop: '8px',
  },
  button: {
    backgroundColor: '#212A2F',
    borderRadius: '5px',
    fontWeight: '700',
    color: '#FFFFFF',
    fontSize: '14px',
    textAlign: 'center' as const,
    display: 'block',
    width: '149px',
    lineHeight: '16px',
    margin: '28px auto 35px',
    height: '45px',
    border: 'none',
  },
  footer: {
    borderTop: '1px solid #CCCCCC',
    // width: '564px',
    width: '100%',
    margin: '0 auto',
    padding: '28px 0 115px 0',
    textAlign: 'center' as const,
    fontFamily: '"Helvetica Neue", sans-serif',
  },
  footerImage: {
    // width: '264px',
    // height: '176px',
    objectFit: 'contain',
    width: '100%',
  },
  hrStyle: {
    borderColor: '#CCCCCC',
    margin: 0,
  },
  footerTitle: {
    fontWeight: '200',
    fontSize: '20px',
    lineHeight: '26px',
  },
  socialIcon: {
    margin: '0px 5px',
    width: '32px',
    height: '32px',
  },
  footerLink: {
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '18.2px',
    color: '#C1C1C1',
  },
  footerSmallText: {
    fontWeight: '300',
    fontSize: '8px',
    lineHeight: '11.58px',
    textAlign: 'start',
    margin: 0,
    fontFamily: "'Noto Sans JP', sans-serif",
  },
}
