import { proxy, subscribe } from 'valtio'
import _ from 'lodash'

const initialTableAttr: {
  id?: string | null
  name?: string | null
} = {
  id: null,
  name: null,
}

const storageName = 'app_client'
let storedTableAttr = { ...initialTableAttr }
if (window.localStorage) {
  const data = localStorage.getItem(storageName)
  let result = data ? JSON.parse(data) : null
  if (!_.isEmpty(result)) {
    console.log(result)
    storedTableAttr = result
  }
}

const tableAttr = proxy({
  state: storedTableAttr,
  reset() {
    tableAttr.state = { ...initialTableAttr }
    localStorage.setItem(storageName, JSON.stringify(initialTableAttr))
  },
})

subscribe(tableAttr, () => {
  console.log('state.arr has changed to', tableAttr.state)
  localStorage.setItem(storageName, JSON.stringify(tableAttr.state))
})

export default tableAttr
