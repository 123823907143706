import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  Card,
  Col,
  Form,
  Row,
  message,
  FormInstance,
  InputNumber,
  Switch,
  Button,
  Typography,
  Input,
  Select,
} from 'antd'

import Gaps from '../../components/Gaps'
import _ from 'lodash'
import appClientStore from '../../lib/store/appClient'
import { useSnapshot } from 'valtio'
import AppSettingsService, {
  AppSettingUpdateState,
  AppSettng,
} from '../../network/services/settings'
import useSWR from 'swr'
import EmailTemplateService, {
  EmailTemplate,
} from '../../network/services/emailTemplate'
// import { useMediaQuery } from 'react-responsive'

const SettingsForm: FC<{
  form: FormInstance<AppSettingUpdateState>
  initialValue: AppSettng
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  refresh: () => any
}> = ({ form, initialValue, refresh, isLoading, setIsLoading }) => {
  const {
    state: { id: appClientId },
  } = useSnapshot(appClientStore)
  const { data: emailTemplates } = useSWR<EmailTemplate[]>(
    EmailTemplateService.getAll
  )

  useEffect(() => {
    form.setFieldsValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    refresh()
  }, [appClientId])

  const handleSubmit = async (values: AppSettingUpdateState) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const { data: result } = await AppSettingsService.update(
        appClientId!,
        values
      )
      if (result.success) {
        message.success('Update successful')
        refresh()
      }
    } catch (error: any) {
      message.error('Update setting error ' + error.message)
    }

    setIsLoading(false)
  }

  return (
    <>
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValue}
        // onFinish={handleSubmit}
        layout='vertical'
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card bordered={false}>
              <Typography.Title level={3} style={{ margin: 0 }}>
                General
              </Typography.Title>
              <Gaps level={2} />
              <Form.Item
                name='send_email_after_days'
                label='Send email after days'
                required
              >
                <InputNumber min={0} />
              </Form.Item>
              <Form.Item
                name='email_after_purchase'
                label='Send email after fulfillment'
                valuePropName='checked'
              >
                <Switch />
              </Form.Item>
              <Form.Item name='email_template_id' label='Email template'>
                <Select
                  style={{ width: 250 }}
                  options={
                    emailTemplates
                      ? emailTemplates.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))
                      : []
                  }
                />
              </Form.Item>
              <Form.Item name='slack_webhook_url' label='Slack Webhook URL'>
                <Input style={{ width: 400 }} />
              </Form.Item>

              <Form.Item name='email_subject' label='Email Subject'>
                <Input
                  style={{ width: 800 }}
                  placeholder='お買い上げになった最近のご注文に関して、レビューをお願いします。'
                />
              </Form.Item>

              <Form.Item name='from' label='From'>
                <Input
                  style={{ width: 250 }}
                  placeholder='reviews@senders.jp'
                />
              </Form.Item>

              <Form.Item name='from_name' label='Send as'>
                <Input style={{ width: 250 }} placeholder='Senders Review' />
              </Form.Item>

              <Form.Item
                name='review_form_domain'
                label='Domain of the review form (Please make sure that you have contacted the team to set up a custom domain for you)'
              >
                <Input style={{ width: 250 }} />
              </Form.Item>

              <Form.Item
                name='read_only'
                label='Read only'
                valuePropName='checked'
              >
                <Switch />
              </Form.Item>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  key={'save'}
                  type='primary'
                  onClick={() => {
                    form.submit()
                  }}
                  loading={isLoading}
                >
                  Save
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
        <Gaps />
      </Form>
    </>
  )
}

export default SettingsForm
