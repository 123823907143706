import { FC, useEffect, useState } from 'react'
import {
  Table,
  Button,
  Empty,
  Modal,
  Row,
  Col,
  Dropdown,
  Tag,
  message,
  Typography,
} from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../lib/store/review_request_table'
import TableFilter, { ITableFilter } from '../../components/TableFilter'
import { IDataResponse, serialize } from '../../network/request'
import Gaps from '../../components/Gaps'
import appClientStore from '../../lib/store/appClient'
import ReviewRequestService, {
  ReviewRequest,
  reviewRequestColors,
} from '../../network/services/reviewRequests'
import { useTranslation } from 'react-i18next'

const ReviewRequestTable: FC<{ total: number; overridePage?: number }> = ({
  total,
  overridePage,
}) => {
  const navigate = useNavigate()
  const {
    state: { id: appClientId },
  } = useSnapshot(appClientStore)
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState<any>(null)
  const [modalType, setModalType] = useState<'delete' | null>(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([])
  const [visible, setVisible] = useState(false)
  const { t } = useTranslation()

  const { data: response } = useSWR<IDataResponse<ReviewRequest>>(
    serialize(ReviewRequestService.getAll(appClientId!), {
      page: overridePage ?? state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters,
    })
  )
  const rows = response ? ReviewRequestService.toRow(response) : []

  const rowSelection = {
    selectedRowKeys,
    onChange: (keySelected: any[]) => {
      setSelectedRowKeys(keySelected)
    },
  }

  const columns = [
    {
      title: t('orderNumber'),
      dataIndex: 'external_order_name',
      key: 'external_order_name',
      sorter: true,
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: t('product'),
      key: 'product',
      render: (record: ReviewRequest) => record.product.name,
      width: 200,
      sorter: true,
    },
    {
      title: t('status'),
      render: (record: ReviewRequest) => (
        <Tag color={reviewRequestColors[record.status]}>{record.status}</Tag>
      ),
      key: 'status',
      sorter: true,
    },
    {
      title: t('createdAt'),
      render: (record: ReviewRequest) =>
        new Date(record.created_at).toLocaleString(),
      key: 'created_at',
      sorter: true,
    },
    {
      title: t('sentAt'),
      render: (record: ReviewRequest) =>
        record.sent_at ? new Date(record.sent_at).toLocaleString() : 'N/A',
      key: 'created_at',
      sorter: true,
    },
    {
      title: t('reviewedAt'),
      render: (record: ReviewRequest) =>
        record.review
          ? new Date(record.review.created_at).toLocaleString()
          : 'N/A',
      key: 'created_at',
      sorter: true,
    },
  ]

  // content={
  //   initialValue.variants.length > 0
  //     ? initialValue.variants.map((item) => item.name).join(',')
  //     : '-'
  // }

  const filterColumns: ITableFilter[] = [
    { key: 'email', name: 'Email' },
    { key: 'external_order_name', name: 'Order Name' },
    { key: 'external_order_id', name: 'Order ID' },
    { key: 'created_at', name: 'Created At' },
    { key: 'status', name: 'Status' },
  ]
  filterColumns.forEach((item) => {
    if (
      tableStore.state?.filters &&
      Object.keys(tableStore.state.filters).includes(item.key)
    ) {
      item.default_value = tableStore.state.filters[item.key]
      item.default = true
    }
  })

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const handleMenuClick = (e: any) => {
    setVisible(true)
    setModalType(e.key)
  }

  const batchDeleteRows = async () => {
    try {
      for (const id of selectedRowKeys) {
        await ReviewRequestService.remove(appClientId!, id)
        tableStore.state.refresh = Math.random()
      }
      message.success(t('deletedCount', { number: selectedRowKeys.length }))
      setVisible(false)
    } catch (e: any) {
      message.error({
        content: `${t('deleteFail')} ${e.message}`,
        className: 'message-error',
      })
    }
  }

  const ActionModal: FC = () => {
    return (
      <Modal
        title={t('deleteNumberRequest', { number: selectedRowKeys.length })}
        open={visible}
        onOk={() => {
          switch (modalType) {
            case 'delete':
              batchDeleteRows()
              break
          }
        }}
        onCancel={() => {
          setVisible(false)
        }}
        okText={t('confirmDelete')}
        cancelText={t('cancel')}
      >
        <Typography.Text style={{ color: 'red' }}>
          {t('deletedNotReversible')}
        </Typography.Text>
      </Modal>
    )
  }

  return (
    <>
      <ActionModal />

      <Row>
        <Col span={12}>
          {selectedRowKeys?.length > 0 ? (
            <Dropdown
              menu={{
                items: [
                  {
                    label: t('delete'),
                    onClick: handleMenuClick,
                    key: 'delete',
                  },
                ],
              }}
              trigger={['click']}
            >
              <Button type='primary'>
                {t('moreActions')} <CaretDownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <></>
          )}
        </Col>
        <Col span={12}>
          <TableFilter
            dropdowns={[]}
            columns={filterColumns}
            hasDate={false}
            setCurrentFilters={setCurrentFilters}
          />
          <Gaps level={3} />
        </Col>
      </Row>

      {rows?.length > 0 ? (
        <Table
          columns={columns}
          dataSource={rows}
          onChange={(pagination, filters, sorter: any) => {
            tableStore.state.sort =
              sorter != null &&
              !_.isEmpty(sorter?.columnKey) &&
              !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null

            tableStore.state.currentPage = pagination.current ?? 1
            tableStore.state.pageSize = pagination.pageSize ?? 10
          }}
          pagination={{
            showSizeChanger: true,
            total,
            pageSize: state.pageSize,
            current: state.currentPage,
            pageSizeOptions: [10, 20, 50, 100, 200, 300],
          }}
          rowSelection={rowSelection}
        />
      ) : (
        <Empty description={'No record found'} />
      )}
    </>
  )
}

export default ReviewRequestTable
