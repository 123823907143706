import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import qs from 'qs'
import _ from 'lodash'

// adonisjs
interface BackendError {
  errorCode: string
  message: string
  error: string
  code: string
  errors: any[]
}

export interface IDataResponse<TDataValues> {
  data: Array<TDataValues>
  meta?: {
    total?: number
    per_page?: number
    current_page?: number
    last_page?: number
    first_page?: number
    first_page_url?: string
    last_page_url?: string
    next_page_url?: string
    previous_page_url?: string
  }
}

export interface IPostResponse<TDataValue> {
  data: TDataValue
  success: boolean
}

const requestHandler = (request: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem('token') || undefined

  if (token != null && typeof token !== 'undefined') {
    if (request.headers) {
      request.headers.Authorization = `Bearer ${token}`
    }

    //ATTENTION: DEV ONLY
    request.headers['x-consumer-username'] = 'benihayashi_2'
  }

  return request
}

const errorHandler = (error: AxiosError<BackendError>) => {
  console.log(error?.response ?? error)

  // check if the error was thrown from axios
  if (axios.isAxiosError(error)) {
    if (error?.response?.status === 401) {
      // unauthorized
      localStorage.clear()
      window.location.reload()
    }

    if (error?.response?.status === 422) {
      // unprocessable entity
      console.log(error.response)

      return Promise.reject({
        message:
          error.response?.data?.errors
            ?.map((e: any) => `${e.field} - ${e.message}`)
            .join('\n') ?? 'Please contact customer support',
      })
    }

    if (error?.response?.status === 404) {
      //not found
      return Promise.reject({
        message: 'No record',
      })
    }

    // Handle errors
    return Promise.reject({
      message: _.toString(
        error.response?.data?.errorCode ??
          error.response?.data?.message ??
          error.response?.data?.error ??
          error.response?.data?.code ??
          'Please try again later'
      ),
    })
  } else {
    // do something else
    // or creating a new error
    throw new Error('different error than axios')
  }
}

const successHandler = (response: AxiosResponse) => {
  return response
}

export const fetcher = async (...args: any) => {
  const url = args[0]

  return client.get(url).then((res) => res.data)
}

export const serialize = (url: string, params: {} | []) => {
  if (typeof params === 'object' || Array.isArray(params)) {
    const matches = url.match(/^(.+?)(\?(.*))?$/)
    const urlWithoutQueryString = (matches && matches[1]) || url
    const queryStringDataFromUrl =
      matches && matches[3] ? qs.parse(matches[3]) : {}
    const queryString = qs.stringify(
      { ...queryStringDataFromUrl, ...params },
      { arrayFormat: 'indices' }
    )
    if (queryString) {
      return `${urlWithoutQueryString}?${queryString}`
    }
    return url
  }

  return url
}

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})
client.interceptors.request.use((request) => requestHandler(request))
client.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
)

export default client
