import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Rate,
  Row,
  Space,
  Spin,
  Typography,
  Upload,
  UploadFile,
  message,
} from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { PlusOutlined } from '@ant-design/icons'
import { FC, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import ReviewService from '../network/services/reviews'
import { UploadChangeParam } from 'antd/es/upload'
import useSWR from 'swr'
import ReviewRequestService, {
  ReviewRequest,
} from '../network/services/reviewRequests'
import { ReviewRequestStatus } from '../network/services/reviewRequests'

const EmailReview: FC = () => {
  return (
    <Row
      justify='center'
      align='middle'
      style={{ height: '100vh', width: '100%' }}
    >
      <Col>
        <Space direction='vertical'>
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <img
              src='/senders logo.png'
              alt=''
              style={{
                objectFit: 'contain',
              }}
              width={200}
            />
            {/* <Typography.Paragraph>Reviews</Typography.Paragraph> */}
          </div>
          <Card style={{ minWidth: '600px' }}>
            <EmailReviewForm />
          </Card>
        </Space>
      </Col>
    </Row>
  )
}

const EmailReviewForm: FC = () => {
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const name = searchParams.get('name') ?? ''
  const title = searchParams.get('title') ?? ''
  const content = searchParams.get('content') ?? ''
  const { data, error, mutate } = useSWR<ReviewRequest>(
    id && token ? ReviewRequestService.publicGet(id, token) : null
  )

  const handleSubmit = async (values: {
    name?: string
    title?: string
    content: string
    score: number
  }) => {
    if (!id || !token) return
    setLoading(true)
    try {
      const { data: response } = await ReviewService.publicEmailCreate({
        token,
        email: data!.email,
        attachments: fileList.map((item) => item.originFileObj!),
        ...values,
      })
      if (response.success) {
        await mutate()
      }
    } catch (e: any) {
      message.error(e.message)
    }
    setLoading(false)
  }

  const handleFileChange = (upload: UploadChangeParam<UploadFile>) => {
    setFileList(upload.fileList)
  }

  if (!token) {
    return (
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        Error invalid URL
      </div>
    )
  }

  if (error) {
    return (
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        Error {error.message}
      </div>
    )
  }

  if (!data) {
    return (
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <Spin />
      </div>
    )
  }

  return (
    <>
      {data.status == ReviewRequestStatus.REVIEWED ? (
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography.Text style={{ fontFamily: 'Noto Sans JP' }}>
            ご協力ありがとうございます！
          </Typography.Text>
        </div>
      ) : (
        <Form
          autoComplete='off'
          form={form}
          onFinish={handleSubmit}
          layout='vertical'
        >
          <Form.Item name='score' label='スコア' initialValue={5}>
            <Rate />
          </Form.Item>
          <Form.Item name='name' label='名前' initialValue={name}>
            <Input />
          </Form.Item>
          <Form.Item
            name='title'
            label='レビューのタイトル'
            initialValue={title}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='商品を使った感想、どのようなシチュエーションで利用されているか、おすすめの使い方などを教えてください'
            name='content'
            initialValue={content}
            rules={[
              {
                required: true,
                message: 'Please enter your review',
              },
            ]}
          >
            <TextArea size='large' autoComplete='off' rows={4} />
          </Form.Item>

          <Upload
            listType='picture-card'
            fileList={fileList}
            onChange={handleFileChange}
          >
            {fileList.length <= 3 && (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              style={{
                background: '#48408F',
              }}
              type='primary'
              htmlType='submit'
              loading={loading}
            >
              送信
            </Button>
          </div>
        </Form>
      )}
    </>
  )
}

export default EmailReview
