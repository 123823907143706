import { Typography } from 'antd'
import Gaps from './Gaps'
import { FC, ReactNode } from 'react'

const ReadOnlyFormItem: FC<{
  title: string
  content: ReactNode
  enableCopy?: boolean
}> = ({ title, content, enableCopy = true }) => {
  return (
    <>
      <div>
        <div>{title}</div>
        <div
          style={{
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            minHeight: 30,
          }}
        >
          {typeof content === 'string' ||
          typeof content === 'number' ||
          typeof content === 'boolean' ? (
            <Typography.Text
              copyable={content !== null && content !== undefined && enableCopy}
            >
              {content ?? '-'}
            </Typography.Text>
          ) : (
            content ?? '-'
          )}
        </div>
      </div>
      <Gaps />
    </>
  )
}

export default ReadOnlyFormItem
